/**
 * @author Rob Shepherd
 */
'use strict';

(function(origin){
	
	var urlContext = "/"

	if(_.endsWith(urlContext, "/")){
		urlContext = urlContext.substring(0, urlContext.length-1);
	}

	var INCWEB = {
		
		context: urlContext,
		
		url: function(path){
			if(path.indexOf('#') == 0){
				return path;
			}
			return origin 
					+ urlContext 
					+ ((!path.startsWith("/")) ? "/" : "")
					+ path
		},
	};
	
	window.INCWEB = INCWEB;
})(window.location.origin);



;(function(){
	var appender = JL.createAjaxAppender('ajaxAppender');
	appender.setOptions({
		url: '/frontend/log'
	})

	JL().setOptions({
		"appenders": [appender]
	});

	setTimeout(function(){
		JL().info({'event':"Page Load", 'request':window.reqId, 'page':location.toString()});
	}, 1000);
	
	
})();
