/**
 * @author Rob Shepherd
 */
'use strict';

;(function(){

angular.module('sbHttpConfig', [])

.run(['$rootScope', '$window', function($rootScope, $window){
}])



.factory('httpHostAndContextInterceptor', ['$location', function(){
  
                      return {
                        request: function(config){
                            if(config.url.charAt(0) == '/' && config.url.indexOf('/gen/partials') != 0 ){
                              var newUrl = INCWEB.url(config.url);
                              //console.log("rewriting URL:" + config.url + " to URL:" + newUrl)
                              config.url = newUrl;
                            }
                            return config
                        }
                      }
  
                      }])

.factory('httpRedirectionInterceptor', ['$location', function($location) {
                    return {
                      'response': function(response) {
							
													var nextUri = response.headers("X-WebClient-NextURI");
													
													if(!!(nextUri)){
														if(nextUri.indexOf("/") == 0){
															window.location.href = INCWEB.url(nextUri); 
														}
													}
													
							
                        							if(!!(response) && !!(response.data)  && response.data.hasOwnProperty("redirect")){
                        								window.location.href = response.data.redirect;
                        								return null;
                        							}
                        							else {
                        								return response;
                        							}
                                  }
                           };
                          }])
.factory('httpOKButErrorWritingInterceptor', ['$location', function($location) {
                    return {
                      'response': function(response) {
										if( (!!(response.data.type)) && response.data.type == "OKButErrorWriting"){
											PNotify.err("Your thing was saved, but something bad happened when responding. try refreshing the page");
											response.data = null;
										}
										return response;			
                                  }
                           };
                          }])                          
                          
.factory('httpErrorNotificationInterceptor', ['$q', function($q) {
                    return {
                      'responseError': function(response) {
                      						
                      						if( !!(response.config.nowarn) ){
                      							return $q.reject(response);
                      						}
                      						
                            				var data = response.data;
                            				if(!data){
                                            	PNotify.err(response.statusText);
                                            	//return response;
                                            }
											              else if(typeof data == 'string'){
                                            	PNotify.err(data);
                                            	//return response;
                                          	}
											              else if(angular.isArray(data)){
                                        var txt = "";
                                        for(var i=0;i<data.length;i++ ){
                                              txt = txt + "<strong>" + i + "</strong>: " + data[i] + "<br>";
                                        }
                                        PNotify.err(txt);
                                        //return response;
                                    }
                                    else if( data.hasOwnProperty("errors")){
                                        var txt = "";
                                        for(var i=0;i<data.errors.length;i++){

                                          var error = data.errors[i];

                                          if(typeof error === 'string'){
                                              if(error == 'net._95point2.core.X$HttpException'){
                                                error = "Error:"
                                              }
                                              txt = txt + " " + error;
                                          }
                                          else if(error && error.hasOwnProperty("field") && error.hasOwnProperty("defaultMessage")){
                                              txt = txt + "<strong>" + error.field + " " + error.defaultMessage + "</strong><br>";
                                          }
                                          else {
                                            txt = txt + " " + JSON.stringify(error);
                                          }
                                        }
                                        PNotify.err(txt);
                                    }
                                    else {
                                        var txt = "";
                                        for(var propertyName in data ){
                                          if(data.hasOwnProperty(propertyName)){
                                            txt = txt + "<strong>" + propertyName + "</strong>: " + data[propertyName] + "<br>";
                                          }
                                        }
                                        PNotify.err(txt);
                                    }

                                    return $q.reject(response);
                                          

                                          
                                  }
                           };
                          }])                          
      


                .config(['$httpProvider', function($httpProvider){
                        $httpProvider.interceptors.push('httpHostAndContextInterceptor');
                        $httpProvider.interceptors.push('httpRedirectionInterceptor');
                        $httpProvider.interceptors.push('httpErrorNotificationInterceptor');
                        $httpProvider.interceptors.push('httpOKButErrorWritingInterceptor');
                }]);

})();
