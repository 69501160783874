(function() {
  'use strict';

  angular
    .module('supportr', [])
    .directive('supportrLink', ['$uibModal','$rootScope','$http',supportrLink])
    
  /** @ngInject */
  function supportrLink( $uibModal, $rootScope, $http) 
  {
    return {
      restrict: 'A',
      scope: true,
      link: function(scope, element, attrs) 
      {
            var code = attrs['supportrLink']
            element.on('click', function(){ 
                                  scope.$apply(function(){
                                                openModal(code, scope, element, attrs);
                                               })
                                });
       }
    }
    
    function openModal(mode, scope, element, attrs)
    {
          var isLoggedIn = true; //AuthService.isAuthenticated();
          
          var newScope = scope.$new();
          
          newScope.request =  {
            mode: mode
          }
          
          if(mode == "general")
          {
              
          }
          else if(mode == "missingFeature")
          {
              var feature = attrs['feature'];
              var args = attrs['args'];
              var label = attrs['label']
              
              var hasInput = attrs['input1'] || attrs['input2']
              
              if(attrs['input1']){
                newScope.request.input1 = null
                newScope.request.input1_label = attrs['input1']
              }

              if(attrs['input2']){
                newScope.request.input2 = null
                newScope.request.input2_label = attrs['input2']
              }
              
              var prompt = hasInput ? "Complete the details" : "Click Submit";
              
              newScope.message = prompt + " and we'll take care of this for you behind the scenes.<br>You'll be kept informed with a support ticket.";
              newScope.request.name = feature;
              newScope.request.subject = args;
              newScope.label = label;   
          }
          else if(mode == "missingFixture")
          {
              var feature = attrs['fixture'];
              var args = attrs['args'];
              var label = attrs['label']
              
              var hasInput = !!(attrs['input1'])
              
              if(hasInput){
                  
                  newScope.fields = []
                  
                  if(attrs['input1']){
                      newScope.fields.push({
                            key: 'input',
                            type: 'input',
                            templateOptions: {
                                required:true,
                                label: attrs['input1'],
                                placeholder: ''
                            }
                        });
                  }
                  
                  if(attrs['input2']){
                      newScope.fields.push({
                            key: 'input2',
                            type: 'input',
                            templateOptions: {
                                required:true,
                                label: attrs['input2'],
                                placeholder: ''
                            }
                        });
                  }
                
              }
              
              var prompt = hasInput ? "Complete the details" : "Click Submit";
              
              newScope.message = prompt + " and we'll take care of this for you behind the scenes as soon as we can.<br>You'll be kept informed with a support ticket.";
              newScope.request.name = feature;
              newScope.request.subject = args; 
              newScope.label = label;   
          }
          else {
            throw 'don\'t know supportr type: ' + mode
          }
          
          
          var modalInstance = $uibModal.open({
              templateUrl: '/gen/partials/supportr/supportrModal.html',
              scope: newScope
          });
            
          function onOK(action){
              
              var data = {
                    mode: newScope.request.mode,
                    name: newScope.request.name,
                    subject: newScope.request.subject,
                    input: "input: " +  newScope.request.input + " - input1: " + newScope.request.input1 + " - input2: " + newScope.request.input2,
                    userEmail: newScope.request.userEmail,        

                    info: {
                            url: location.href,
                            userAgent: navigator.userAgent,
                            screen: screen.width + "x" + screen.height,
                            viewport: $(window).width()  + "x" + $(window).height(),
                            page:  $(document).width()  + "x" + $(document).height()
                    }
                };
              
              $http.post('/support/request', data).then(function(resp){
                  if(resp.data.msg == 'OK'){
                    PNotify.ok("Your support request has been sent", "Success")
                  }
                  else {
                    PNotify.ok("Your support request has been sent", "Success")
                  }
              })
          }
          
          function onDismiss(){
          }
          
          modalInstance.result.then(onOK, onDismiss);
    }
  }
})();