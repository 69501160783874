(function(){

    moment.defaultFormat = 'YYYY-MM-DDTHH:mm:ssZZ';

    angular.module('sbMoment', [])
    .filter('moment', momentFilter)
    .constant('momentize', {
        incident: function(inc, forwards){

            var fn = forwards ? momentise : unmomentise

            fn(inc,'alertedAt')

            _(inc.alerts).each(function(alert){
                fn(alert,'alertedAt')
            })

            _(inc.reports).each(function(report){
                fn(report,'startedAt');
                fn(report,'finishedAt');
                fn(report,'created');
                fn(report,'updated');
            })

            return inc;

        }
    })

    function momentFilter() {
      var filter = function(item, format, parseFormat) {
          
          if(!item){
              return null;
          }
          
          var the_moment;

          if(moment.isMoment(item))
          {
                the_moment = item;
          }
          else if(typeof item === 'string' && typeof parseFormat === 'string')
          {
                the_moment = moment(item,parseFormat);
          }
          else{
              the_moment = moment(item, 'YYYY-MM-DDTHH:mm:ssZZ');
          }
          
          if(format == "fromNow"){
              return the_moment.fromNow();
          }
          else {
              return the_moment.format(format);
          }
          
      }
      
      filter.$stateful = true;
      return filter;
    }

    function momentise(obj, prop){
        if( obj[prop] == null || typeof obj[prop] === 'undefined' ){
            return;
        }
        obj[prop] = moment(obj[prop])
    }

    function unmomentise(obj, prop){
        if( moment.isMoment(obj[prop]) ){
            return moment(obj[prop]).format('YYYY-MM-DDTHH:mm:ssZZ');
        }
        return obj[prop];
    }

    

})();
/* */