'use strict'
;(function(){

	var EVENT_INCIDENT_RELOAD = "EVENT_INCIDENT_RELOAD";
	
	angular.module('inc-teamadmin', ['ui.router'])
	.config(['$stateProvider', '$urlRouterProvider', function($stateProvider, $urlRouterProvider) {

		$stateProvider.state({
			name:'main',
			url: '/main',
			templateUrl:'/gen/partials/admin/teamsetup.html',
			controller: function(){},
			controllerAs:'vm'
		})
		.state({
			name:'tags',
			url: '/tags',
			templateUrl:'/gen/partials/admin/tags.html',
			controller:'TeamTagsSetupController',
			controllerAs:'vm'
		})
		.state({
			name:'members',
			url: '/members',
			templateUrl:'/gen/partials/admin/members.html',
			controller:'TeamMemberSetupController',
			controllerAs:'vm'
		})


		.state({
			name:'advanced',
			url: '/advanced',
			templateUrl:'/gen/partials/admin/advanced.html',
			controller:'TeamAdvancedSetupController',
			controllerAs:'vm'
		})



		$urlRouterProvider.otherwise('/main');
	}])
	  
	 .factory('entities', ['momentize', function(momentize){

		var obj = {
			team: jsPageModel.team
		}

	 	return obj;
	 }])
	.run(['$rootScope', function ($rootScope) 
	{
		$rootScope.openHelp.module = "incident";
	}])
	.controller("TeamTagsSetupController", ['$scope', '$http', 'entities', function($scope, $http, entities){
		var vm = this;
		vm.team = entities.team;



		$http.get("/admin/" + entities.team + '/tags').then(function(resp){
			vm.tags = resp.data;
		})

		vm.showEditTag = function(editTag){
			vm.editTag = editTag;
			vm.mode = 'editTag';
			$('html, body').animate({
				scrollTop: $("div#edit_form").offset().top
			}, 300);
		}

		vm.saveEditTag = function(){
			$http.put("/admin/" + entities.team + "/tag", vm.editTag).then(function(resp) {
				vm.mode = null;
				vm.editTag = null;
				PNotify.ok("Tag Saved")
			})
		}

		vm.deleteTag = function(tag){
			if(confirm("really delete tag '" + tag.name + "' ? \n (Note, this won't remove this tag from any reports)"))
			{
				$http.delete("/admin/" + entities.team + "/tag/" + tag.id).then(function(resp){
					PNotify.warn("Tag: " + tag.name + " deleted"); 
					_.remove(vm.tags, function(t){ 
						return t.id == tag.id 
					});
				})
			}
		}

		vm.saveNewTag = function(){
			$http.post("/admin/" + entities.team + "/tag", vm.newTag).then( function(resp){
				vm.tags.push(resp.data);
				vm.newTag = {};
				PNotify.ok("New Tag Saved")
			})
		}

		vm.newTag = {
			
		}

	}])
	
	.controller("TeamAdvancedSetupController", ['$scope', '$http', 'entities', 'Upload', '$timeout', function($scope, $http, entities, Upload, $timeout){
		var vm = this;
		vm.team = entities.team;

		vm.settings = {};

		vm.teamLogo = setLogoUrl()

		function setLogoUrl(){
			return "/api/v1/misc/logo/" + entities.team + "?v=" + Math.ceil(Math.random()*9999999)
		}

		$http.get("/admin/" + entities.team + "/prefs").then(function(resp){
			vm.prefs = sortAll(resp.data, {});
		})

		function sortOne(inputObject, storageObject){
			storageObject[inputObject.name] = inputObject;
			return storageObject;
		}

		vm.addStringToArray = function(arr){
			arr.push('');
		}

		function sortAll(inputObjects, storageObject){
			_.each(inputObjects, function(inputObject){
				sortOne(inputObject, storageObject);
			})
			return storageObject;
		}

		vm.submitPref = function(prefName){

			var value = vm.prefs[prefName]
			var valueObj = value.valueObj;

			if(_.isArray(valueObj.value)){
				_.remove(valueObj.value, function(el){ return el == '' || typeof el == 'undefined' || el == null});
			}

			$http.post("/admin/" + entities.team + "/pref/" + prefName, valueObj )
					.then(function(resp){
						sortOne(resp.data, vm.prefs);
					});
		}

		vm.uploadPic = function(file) {
			file.upload = Upload.upload({
			  url: "/admin/" + entities.team + "/pref/file/logo" ,
			  data: {file: file},
			});
		
			file.upload.then(function (response) {
			  $timeout(function () {
				file.result = response.data;
				PNotify.ok("Uploaded OK")
				vm.teamLogo = setLogoUrl();
				vm.picFile = null;
			  });
			}, function (response) {
			  if (response.status > 0)
			  	PNotify.err("Upload error: " + response.status)
			}, function (evt) {
			  // Math.min is to fix IE which reports 200% sometimes
			console.log("Progress: " + Math.min(100, parseInt(100.0 * evt.loaded / evt.total)));
			});
			}
	}])

	.controller("TeamMemberSetupController", ['$scope', '$http', 'entities', '$uibModal', function($scope, $http, entities, $uibModal){
		var vm = this;
		vm.team = entities.team;

		$http.get("/admin/" + entities.team + "/members").then(function(resp){
			vm.members = resp.data;
		})

		vm.editMember = function(member){
			return $uibModal.open({
                templateUrl: 'editMemberModal.html', //'tagpickr.modal.html',
                size: 'md',
                controller: function(){},
                controllerAs: 'vm',
                resolve: {
                    member: member
                }
           }).result.then(function(resp){
               if(resp === 'OK'){
                   return $q.resolve( "OK" );
               }
               else {
                   return $q.resolve("Cancel");
               }
           }, function(){
               return $q.resolve("Bad!");
           })
		}

		
	}])
	
	
	.filter('strRepl', function () {
		return function (input, from, to) {
		  input = input || '';
		  from = from || '';
		  to = to || '';
		  return input.replace(new RegExp(from, 'g'), to);
		};
	  });
	



})();
