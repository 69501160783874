/** Polyfills 
 *    fuck you IE
 */
(function(){

    Array.prototype.move = function (pos1, pos2) {
    // local variables
    var i, tmp;
    // cast input parameters to integers
    pos1 = parseInt(pos1, 10);
    pos2 = parseInt(pos2, 10);
    // if positions are different and inside array
    if (pos1 !== pos2 &&
        0 <= pos1 && pos1 <= this.length &&
        0 <= pos2 && pos2 <= this.length) {
        // save element from position 1
        tmp = this[pos1];
        // move element down and shift other elements up
        if (pos1 < pos2) {
            for (i = pos1; i < pos2; i++) {
                this[i] = this[i + 1];
            }
        }
        // move element up and shift other elements down
        else {
            for (i = pos1; i > pos2; i--) {
                this[i] = this[i - 1];
            }
        }
        // put element from position 1 to destination
        this[pos2] = tmp;
    }
}

    /**
     * startsWith
     */
    if (!String.prototype.startsWith) {
        Object.defineProperty(String.prototype, 'startsWith', {
            value: function(search, pos) {
                return this.substr(!pos || pos < 0 ? 0 : +pos, search.length) === search;
            }
        });
    }

/**
 * <time datetime="2018-05-02 16:16:33.58">2018-05-02 16:16:33.58</time>
 * gives
 * <time datetime="2018-05-02 16:16:33.58">26 minutes ago</time>
 */
if(window.jQuery && window.moment){
    jQuery(document).ready(function(){
        jQuery("time[datetime]").each(function(i,el){
            try {
                el = $(el);
                var dt = el.attr("datetime");
                el.html(moment(dt).fromNow());
            }
            catch(e){}//ignore 
        })
    });
}

window.onresize = function () {
    $(".sb-affix").each(function(i,el){
        var w = $(el).parent().width();
        $(el).width(w);
    })
    
};

$(document).ready(function(){
    window.onresize();
})

})()