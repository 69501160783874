'use strict'
;(function boot(){

	var EVENT_INCIDENT_RELOAD_FULL = "EVENT_INCIDENT_RELOAD_FULL";
	var EVENT_INCIDENT_RELOAD_SUBJECT = "EVENT_INCIDENT_RELOAD_SUBJECT";
	
	angular.module('inc-edit', ['ui.router'])
	
	.config(['$stateProvider', '$urlRouterProvider', function routeCfg($stateProvider, $urlRouterProvider) {

		$stateProvider.state({
			name:'editor',
			abstract: true,
			templateUrl:'/gen/partials/incident/editor.html',
			controller:'IncidentEditorController',
			controllerAs:'vm'
		})
		.state({
			name:'editor.new',
			url:'/new',
			templateUrl:'/gen/partials/incident/editor-new.html',
			controller:'IncidentEditorNewController',
			controllerAs:'vm',
			data: {
				noLeftMenu: true
			}
		})
		.state({
			name:'editor.teams',
			url:'/teams',
			templateUrl:'/gen/partials/incident/editor-teams.html',
			controller:'IncidentEditorTeamsController',
			controllerAs:'vm'
		})
		.state({
			name:'editor.main',
			url:'/main',
			templateUrl:'/gen/partials/incident/editor-main.html',
			controller:'IncidentEditorMainController',
			controllerAs:'vm'
		})
		
		.state({
			name:'editor.subjects',
			url:'/subjects',
			templateUrl:'/gen/partials/incident/editor-subjects.html',
			controller:'IncidentEditorSubjectController',
			controllerAs:'vm'
		})
		.state({
			name:'editor.subject',
			url:'/subject/:subjectId',
			templateUrl:'/gen/partials/incident/editor-subject.html',
			controller:'IncidentEditorSubjectController',
			controllerAs:'vm'
		})
		.state({
			name:'editor.alerts',
			url:'/alerts',
			templateUrl:'/gen/partials/incident/editor-alerts.html',
			controller:'IncidentEditorAlertsController',
			controllerAs:'vm'
		})
		.state({
			name:'editor.report',
			url:'/report/:reportId',
			templateUrl:'/gen/partials/incident/editor-team-report.html',
			controller:'IncidentEditorTeamReportController',
			controllerAs:'vm'
		})
		;

		$urlRouterProvider.otherwise('/main');


	}])
	.run(['$transitions', '$rootScope', function($transitions, $rootScope){
		$transitions.onEnter({}, function(transition, state){
			/* allows some states (E.g. new) to declare noLeftMenu to suppress the main editor menu */
			if(state.data && state.data.noLeftMenu){
				$rootScope.noLeftMenu = true;
			}
			else {
				$rootScope.noLeftMenu = false;
			}
		})
	}])

	/**
	 * bootstrap's data-spy="affix" runs on load() and so doesn't get picked up on dynamic page changes later.
	 * This does a manual call at component link time. 
	 * And sets the width too.  (See also window.onresize in common.js)
	 */
	.directive('sbAffix', [function sbAffix(){
		return {
			restrict: 'C',
			link: function(scope, el, attr){

				var w = el.parent().width();
        		el.width(w);

				var top = el.offset().top;
				el.affix({offset:{top:top-121}})
			}
		}
	}])

	/*
	.config(['$routeProvider',
	  function($routeProvider) {
	    $routeProvider.
	      when('/', {
	        templateUrl: '/gen/partials/incident/editor.html',
	        controller: 'IncidentEditorController',
			controllerAs: 'vm'
		  })
	  }])
	*/

	  .factory('dirtyTracker', [function dirtyTracker($rootScope){
		
		function clear(){
			return { 
				main: {},
				reports: {},
				subjects: {},
			}
		}

		var tracker = clear()

		/** 
		 * A subject's personal data field is brough indpendently 
		 * but we can't just replace the whole 'cached' item a it may be edited.
		 * This function supports patching a particular property 
		 */
		function updateCachedProp(type, id, property, value){
			tracker[type][id]['cached'][property] = value;
		}

		function getIgnores(type){
			switch(type){
				case 'main': return ['reports', 'subjects', 'alerts', 'hours', 'persons'];
				default: return [];
			}
		}

		function setup(type, obj){
			var ignores = getIgnores(type)
			var cached = _.omit(angular.copy(obj), ignores);
			tracker[type][obj.id] = { dirty: false, cached:cached };
		}

		function setupSets(type, inc){
			tracker[type]['set'] = { dirty: false, cached: inc[type].length };
		}

		/* generate a watcher function to handle dirty tracking for each element */
		function getWatcher(type) {
			var ignores = getIgnores(type);
			return function(newValue, oldValue){

				if(!oldValue.id){
					// this is a new entity (subject likely)
					// implicity dirty! 
					return;
				}

				var latest = _.omit(newValue, ignores);
				var tracked = tracker[type][oldValue.id];
				if( tracked && tracked.cached ){
					var isDirty = !angular.equals( tracked.cached, latest) // using angular.equals removes $vars like repeat trackers
					tracked.dirty = isDirty;
				}
				else {
					tracker[type][oldValue.id].cached = latest;
					tracker[type][oldValue.id].dirty = false;
				}
			}
		}

		function getSetWatcher(type) {
			return function(newValue, oldValue){
				if(newValue != tracker[type]['set'].cached ) {
					tracker[type]['set'].dirty = true;
				}
				else {
					tracker[type]['set'].dirty = false;
				}
			}
		}

		function isDirty(item){  /* deduce item and check diiirtyness */
			if( !item.id) {
				return true;
			}
			return _.get(tracker, 'subjects.' + item.id + ".dirty") ||
					_.get(tracker, 'reports.' + item.id + ".dirty") ||
					_.get(tracker, 'main.' + item.id + ".dirty") 

		}

		function anyDirtyIn(setKey){
			return _(tracker[setKey]).map(_.property('dirty')).some();
		}

		function anyDirty(){
			return anyDirtyIn('main') || anyDirtyIn('reports') || anyDirtyIn('subjects');
		}

		function deleteFrom(type, id){
			delete tracker[type][id];
			tracker[type]['set'].cached -= 1;
		}

		return {
			clear: clear,
			tracker: tracker,
			setup: setup,
			anyDirtyIn:anyDirtyIn,
			anyDirty: anyDirty,
			getWatcher:getWatcher,
			getSetWatcher:getSetWatcher,
			setupSets:setupSets,
			deleteFrom:deleteFrom,
			isDirty:isDirty,
			updateCachedProp:updateCachedProp
		}

	  }])

	  .run(['$rootScope', 'dirtyTracker', function run1($rootScope, dirtyTracker){
		$rootScope.dirtyTracker = dirtyTracker;
		$rootScope.forms = {};

		$(window).on('beforeunload', function (e)
		{
			if( $rootScope.dirtyTracker.anyDirty() ){
				e.returnValue = "You have unsaved records."
				return "You have unsaved records."
			}
		});

		
	  }])
	  
	  
	 .factory('entities', ['momentize', '$rootScope', 'dirtyTracker', '$q', '$state', '$http', 'confirmr', function entitiesFactory(momentize, $rootScope, dirtyTracker, $q, $state, $http, confirmr){

		var editable = jsPageModel.perms.editable;
		var readable = jsPageModel.perms.readable;

		var has = function(arr, obj){
			return _(arr).includes(obj) || (arr.length ==1 && arr[0] == '*')
		}

		var canEdit = function(team){
			if( _.isArray(team)){
				return _.some(team, function(aTeam){
					return canEdit(aTeam)
				})
			}
			return has(editable, team);
		}

		var canRead = function(team){
			if( _.isArray(team)){
				return _.some(team, function(aTeam){
					return canRead(aTeam)
				})
			}
			return has(readable, team) || canEdit(team);
		}

		var isTeamOnlyEditable = function(team){
			return editable.length == 1 && editable[0] == team;
		}

		var isTeamOnlyReadable = function(team){
			return (readable.length == 1 && readable[0] == team) || isTeamOnlyEditable(team);
		}

		
		/* copy the new incident to the entities holder and reload the state */
		function reload(updateObj){
			this.incident = updateObj
			$rootScope.$broadcast(EVENT_INCIDENT_RELOAD_FULL, updateObj);
		}

		function checkForOpenReport() {
			var that = this;

			if(  _(this.incident.reports).some(function(r){
				return r.team == that.team && r.status == 'Open';
			}) ){
				return $q.resolve(true);
			}

		

			var lastClosedReports = _(this.incident.reports).filter(function(r){
				return r.team == that.team;
			}).orderBy('updated', ['desc']).value();

			if(lastClosedReports.length == 0){
				PNotify.err("Could not find report to open.  Error!")
				return $q.reject()
			}

			return confirmr.confirm("TeamReport is Closed", 
									"Your TeamReport record needs to be open to edit this, click OK to re-open your Team Report. (Remember to close it again afterwards)", 
									'OK', 'Cancel', 'md')
							.then(function(){
								var report = lastClosedReports[0]; // TODO more than one?
								report.status = 'Open';
								return that.doSave(report, true, true)
									.then(function(){
										PNotify.ok("TeamReport Record Re-Opened")
										return true;
									});
							})
		}

		function doSave(report, no_notify, onLeave){
			var that = this;
			var update = {
				incidentId: this.incident.main.id,
				report: report
			}

			return $http.put("/api/v1/incidents/" + that.team + "/incident/" + this.incident.main.id + "/report" + (report.id ? ('/' + report.id):''), update )
			.then(function(resp){
				if(!no_notify){
					PNotify.ok("Saved TeamReport")
				}
				//vm.inInAfterSave = true; // allow state reloading without prompt;
				that.reload(resp.data)
				if(!onLeave){
					$state.reload();
				}
				
				return true;
			});
		}
		

		var obj = {
			team: jsPageModel.team,
			teamPrefs: jsPageModel.teamPrefs,
			teams: jsPageModel.teams,
			incident: jsPageModel.incident, //momentize.incident(jsPageModel.incident, true)
			fixtures: jsPageModel.fixtures,
			canEdit: canEdit,
			canRead: canRead,
			isTeamOnlyEditable:isTeamOnlyEditable,
			isTeamOnlyReadable:isTeamOnlyReadable,
			checkForOpenReport:checkForOpenReport,
			doSave:doSave,
			reload: reload
		}

	 	return obj;
	 }])
	.run(['$rootScope', function run2($rootScope) 
	{
		$rootScope.openHelp.module = "incident";
	}])

	.run(['$transitions',function run3($transitions){
		
		$transitions.onEnter({to:'**'}, function(transtion){
			var toState = transtion.to();
			if(toState.data == null){
				toState.data = {}
			}
		} )
	}])

	.controller('IncidentEditorController', ['$scope', 'entities', 'tagService', 'tagpickr', '$http', '$state', '$rootScope', 'dirtyTracker', '$transitions', '$interval', 
									 function incidentEditorController($scope, entities, tagService, tagpickr, $http, $state, $rootScope, dirtyTracker, $transitions, $interval){
		var vm = this;

		vm.tags = tagService.getAllGrouped();

		vm.misc = {}; // hold misc runtime state to keep domain objects clean

		vm.misc.isOwned = entities.canEdit(entities.incident.main.owningTeams)

		vm.misc.fixtureLookup = {}
		_(entities.fixtures).each(function(v,k){
			vm.misc.fixtureLookup[k] = _(entities.fixtures[k]).flatMap(function(a){return a}).keyBy('id').value()
		});
		vm.team = entities.team;

		// called on page load and after every save event.
		function load(){
			vm.inc = entities.incident; // will have been updated before hand
			console.log("IncidentEditorController - load(): " + vm.inc.version )
			dirtyTracker.clear();
			dirtyTracker.setup('main', vm.inc.main);
			dirtyTracker.setupSets('reports', vm.inc);
			dirtyTracker.setupSets('subjects', vm.inc);

			_.each(vm.inc.reports, function(r){ 
				if( entities.canEdit(r.team) ){ // only watch for dirty if can actually edit
					dirtyTracker.setup('reports', r); 
				}
			})
			
			_.each(vm.inc.subjects, function(s){ 
				if( entities.canEdit(s.owningTeam) ){ // only watch for dirty if can actually edit
					dirtyTracker.setup('subjects', s); 
				}
			})


		};
		load(); // load first time;
		$rootScope.$on(EVENT_INCIDENT_RELOAD_FULL, load); // reload when it loads

		vm.selectedReport = {};

		vm.getSubjectLabel = getSubjectLabel
		vm.newSubject = function(){

			entities.checkForOpenReport()
			.then(function(){
				var created = moment().format('YYYY-MM-DDTHH:mm:ssZZ');
				vm.inc.subjects.push({ status: 'Open', created:created, owningTeam:jsPageModel.team, misper: {} })
				PNotify.ok("Subject Record Created")
				$state.go("editor.subject", {subjectId:vm.inc.subjects.length -1 })
			})

			
		}

		
		vm.editTreatments = function(subject){
			var model = subject.treatments || []
			openTagPicker('Treatments', model, 'TREATMENT')
			.then(function(newTr){
				subject.treatments = newTr;
			})
		}

		vm.editTagSet = function(entity, property, title, category){
			var model = _.get(entity,property) || [];
			openTagPicker(title, model, category)
			.then(function(newSet){
				_.set(entity,property,newSet);
			})
		}

		var openTagPicker = function(title, model, category){
			var catTags = vm.tags[category]
			return tagpickr.pick(title, model, catTags)
		}

		vm.onChangeDates = function(att){
			if(moment.isMoment(att.finishedAt) && moment.isMoment(att.startedAt) )
			{
				att.hours = att.finishedAt.diff(att.startedAt, 'hours')
				console.log(att.hours)
			}
		}

		vm.mainOpen = function(){
			return entities.canEdit(vm.inc.main.owningTeams) && vm.inc.main.status == 'Open'
		}

		vm.anySubjectOpen = function(){
			return _(vm.inc.subjects).some(function(s){
				return s.owningTeam == entities.team && s.status == 'Open';
			})
		}

		vm.myTeamReportOpen = function(){
			return _(vm.inc.reports).some(function(r){
				return r.team == entities.team && r.status == 'Open';
			})
		}

		$scope.$watch('vm.inc.subjects.length', dirtyTracker.getSetWatcher('subjects'),false);
		$scope.$watch('vm.inc.reports.length', dirtyTracker.getSetWatcher('reports'),false);

		var pageStart = new Date().getTime();
		$interval(function(){
			$http.get("/api/v1/misc/ping", {noBlock: true}).then(function(resp){
				if(resp.data && resp.data.response == 'pong'){
					if(resp.data.user == 'NOT_AUTHENTICATED'){
						location.href= "/";
					}
				}
			}).catch(function(e){
				location.href = "/";
			})
		}, 60 * 1000 * 2 )
		
	}])

	.controller('IncidentEditorMainController', ['$scope', 'entities', 'tagService', 'tagpickr', '$state', 'dirtyTracker', 'confirmr', '$rootScope', 'mainDetailsValidator', '$http',
												function incidentEditorMainController($scope, entities, tagService, tagpickr, $state, dirtyTracker, confirmr, $rootScope, mainDetailsValidator, $http){
		var vm = this;
		vm.misc = {};
		vm.gridRefRegex = /^[SHNOT][A-Z](\d{6}|\d{8}|\d{10})$/
		
		vm.inc = entities.incident;

		vm.misc.isOwned = entities.canEdit(vm.inc.main.owningTeams)
		vm.misc.formDisabled = !vm.misc.isOwned || vm.inc.main.status === 'Closed';
		
		vm.misc.fixtures = entities.fixtures;
		
		if(vm.misc.isOwned){
			$scope.$watch('vm.inc.main', dirtyTracker.getWatcher('main'),true);
		}

		vm.getRecordStatus = function(){
			if(vm.inc.main.status == 'Closed'){
				return "✔ Closed"
			}
			if(vm.inc.main.status == 'Open'){
				if(dirtyTracker.isDirty(vm.inc.main)){
					return 'Modified';
				}
				else {
					return "Open   ✔ Saved"
				}
			}
		}

		vm.getNextAction = function (){
			if(dirtyTracker.isDirty(vm.inc.main)){
				return 'save';
			}
			if(vm.inc.main.status == 'Open'){
				return 'close'
			}
			if(vm.inc.main.status == 'Closed'){
				return 'open'
			}
		}

		/* triggered by transition when the page is being exited */
		this.uiCanExit = function uiCanExit(){
			if(vm.inInAfterSave){
				return true;
			}
			if(vm.inc.main){
				if( dirtyTracker.isDirty(vm.inc.main)) {
					return confirmr.confirm("Hang on there...","This Incident record is not saved. Click OK to save or cancel to stay on this page", "OK, Save it!", "Cancel", 'md')
					.then(function(resp){
						return vm.save(true);
					}).catch(function(e){
						return false;
					})
				}
			}
		}

	/**
	 * returns boolean true if can save, otherwie false and a dialog or errors are shown. 
	 */
	vm.checkCanSave = function checkCanSave()
	{
		if($rootScope.forms.main.$invalid){

			var fields = _($rootScope.forms.main)
							.filter(function(p,k){ return !k.startsWith("$") && p.$invalid })
							.value()

			_(fields).each(function(p){
				p.name = fieldNames[p.$name] || p.$name
			})
					
			var body = {
				tpl: 'onSaveErrors.html',
				data: {
					form: $rootScope.forms.main,
					fields: fields
				}
			}

			
			confirmr.confirm("Cannot Save Incident Details",body, "OK", null, 'md')
			.then(function(resp){
				
			}).catch(function(e){/*noop*/})

			return false;
		}

		return true;
	}

	vm.doSave = function(main, no_notify, onLeave){
		var update = {
			incidentId: vm.inc.main.id,
			incident: main
		}

		return $http.put("/api/v1/incidents/" + jsPageModel.team + "/incident/" + vm.inc.main.id + "/main/" + main.id, update )
		.then(function(resp){
			if(!no_notify){
				PNotify.ok("Saved Incident Details")
			}
			vm.inInAfterSave = true; // allow state reloading without prompt;
			entities.reload(resp.data)
			if(!onLeave){
				$state.reload();
			}
			
			return true;
		});
	}

	vm.save = function(onLeave){

		if(vm.misc.formDisabled){
			return
		}

		if($rootScope.forms.main.$pristine && !dirtyTracker.isDirty(vm.inc.main)){
			PNotify.warn("No Changes...")
			return;
		}

		if(!vm.checkCanSave()){
			return false;
		}
		else {
			return vm.doSave(vm.inc.main, false, onLeave||false )
		}
	}

	/*
	* true can save, false errors and modal is shown
	*/
	vm.checkCanClose = function checkCanClose()
	{
		var errors = mainDetailsValidator.validateForClose(vm.inc.main);

		if(!errors.length){
			return true;
		}
		else {
			_(errors).each(function(p){
				p.name = fieldNames[p.$name] || p.$name
			})
						
			var body = {
				tpl: 'onSaveErrorsMain.html',
				data: {
					fields: errors
				}
			}
		
			confirmr.confirm("Cannot Close Main Record",body, "OK", null, 'md')
		}
		return false;
	}

	vm.close = function(){
		if(!vm.checkCanSave() || !vm.checkCanClose() ){
			return;
		}
		else{
			vm.inc.main.status = 'Closed';
			vm.doSave(vm.inc.main, true, false);
			PNotify.ok("Main Details Closed and Saved")
		}
	}

	vm.open = function(){

		entities.checkForOpenReport()
		.then(function(){
			vm.inc.main.status = 'Open';
			return vm.doSave(vm.inc.main, true, false)
			.then(function(){
				PNotify.ok("Main Details Record Re-Opened")
			});
		})
	}

	var fieldNames = {
		'title': 'Title', 
		'reason': 'Reason', 
		'activity': 'Activity', 
		'search': 'Search Type',
		'rescue': 'Rescue Type',
		'location': 'Location',
		'partySize': 'Party Size'
	}
		
	}])

	.controller('IncidentEditorNewController', ['$scope', 'entities',  '$state', '$http', '$rootScope', function incidentEditorNewController($scope, entities, $state, $http, $rootScope){
		var vm = this;
		vm.misc = {};
		vm.inc = entities.incident;
		console.log("IncidentEditorNewController - init<>: " + vm.inc.main.version )

		vm.misc.isOwned = entities.canEdit(vm.inc.main.owningTeams)

		if( ! vm.misc.isOwned ){
			$state.go("editor.main")
			return;
		}

		vm.teams = entities.teams;

		vm.addTeam = function(newTeam){
			if( ! _(vm.inc.main.owningTeams).includes(newTeam) ){
				vm.inc.main.owningTeams.push(newTeam);
			}
			vm.selectedNewTeam = null;
		}

		vm.delTeam = function(team){
			if(team != entities.team){
				_.remove(vm.inc.main.owningTeams, team);
			}
		}

		
		vm.saveAndContinue = function(){
			var v_submitted = vm.inc.main.version;
			$http.put("/api/v1/incidents/" + jsPageModel.team + "/incident/" + vm.inc.main.id + "/initial", vm.inc.main).then(function(resp){
				entities.incident = resp.data;
				console.log("Saved and Loading Updated Incident v" + v_submitted + " -> v" + entities.incident.main.version)
				$rootScope.$broadcast(EVENT_INCIDENT_RELOAD_FULL);
				$state.go("editor.main");
			});
		}
		
	}])






	.controller('IncidentEditorTeamsController', ['$scope', 'entities',  '$state', '$http', '$rootScope', 'confirmr',
			function incidentEditorTeamsController($scope, entities, $state, $http, $rootScope, confirmr){
		var vm = this;
		vm.misc = {};
		vm.inc = entities.incident;

		

		vm.team = entities.team;
		//console.log("IncidentEditorTeamsController - init<>: " + vm.inc.main.version )

		vm.misc.isOwned = entities.canEdit(vm.inc.main.owningTeams)
		vm.teams = entities.teams;

		vm.removeSelf = function(){
			var anySubjects = _.some(vm.inc.subjects, function(s){
				return s.owningTeam = vm.team;
			})

			if(anySubjects){
				confirmr.confirm("Cannot Remove " + vm.team,"You have subject(s).  Delete or transfer them first", "OK", null, 'md')
				return;
			}

			var anyReports = _.some(vm.inc.reports, function(r){
				return r.team = vm.team;
			})

			if(anyReports){
				// TODO double confirm
				confirmr.confirm("Cannot Remove " + vm.team,"You have existing team report(s). <br><br> Click OK to delete all team reports and documents for " + vm.team + " for " + vm.inc.main.logRef + ". <br><strong class=\"text-danger\">You cannot Un-Do this!</strong>", "Yes, Delete!", "Cancel", 'md')
				.then(function(o){
					var challenge = {
						request: vm.inc.main.logRef,
						response: null
					}

					var body = {
						tpl: 'challengeConfirm.html',
						data: {
							challenge: challenge
						}
					}

					confirmr.confirm("Confirm delete reports for " + challenge + "/" + vm.team, body, 'Confirm', "Cancel", "md")
					.then(function(){
						if(challenge.request === challenge.response){
							alert("YES!");
						}
						else {
							PNotify.err("Response didn't match! Not deleting")
						}
					})
					.catch(function(e){ /* NOOP */ })

				})
				.catch(function(e){ /* NOOP */ })
				return;
			}
			

			

		}


		vm.addTeam = function(type, newTeam){
			$http.post("/api/v1/incidents/" + jsPageModel.team + "/incident/" + vm.inc.main.id + "/add/" + type + "/" + newTeam)
				.then(function(resp){
					PNotify.ok("Saved Team")
					vm.inInAfterSave = true; // allow state reloading without prompt;
					entities.reload(resp.data)
					$state.reload();
					return true;
				})
				
			vm.selectedNewTeam = null;
		}

		/*
		vm.delTeam = function(team){
			if(team != entities.team){
				_.remove(vm.inc.main.owningTeams, team);
			}
		}
		*/

		
		
		
	}])





	.controller('IncidentEditorSubjectController', ['$scope', 'entities', 'tagService', 'tagpickr', '$http', '$stateParams', '$state', 'dirtyTracker', 'confirmr', '$rootScope', 'subjectValidator',
											function incidentEditorSubjectController($scope, entities, tagService, tagpickr, $http, $stateParams, $state, dirtyTracker, confirmr, $rootScope, subjectValidator){
		var vm = this;
		vm.inc = entities.incident;
		vm.team = entities.team;

		vm.diagTree = diagTree;
		vm.drugsList = drugsList;

		vm.misc = {};
		vm.misc.fixtures = entities.fixtures;
		vm.misc.gridRefRegex = /^[SHNOT][A-Z](\d{6}|\d{8}|\d{10})$/

		function load(){
			if($stateParams.subjectId){
				vm.subject = _.find(vm.inc.subjects, function(s){ return s.id == $stateParams.subjectId});
				if(!vm.subject){
					vm.subject = _.find(vm.inc.subjects, function(s, idx){ return idx == $stateParams.subjectId});
					if(vm.subject && vm.subject.id){
						$state.go("editor.subject", {subjectId:vm.subject.id})
					}
					if(!vm.subject ){
						$state.go("editor.main")
					}
				}
			}
			else{
				if(vm.inc.subjects.length > 0){
					$state.go("editor.subject", {subjectId:vm.inc.subjects[0].id || 0})
				}
			}
		}
		load();

		function setupMisc(){
			vm.misc.isOwned = entities.canEdit(vm.subject.owningTeam);
			vm.misc.canShow = entities.canRead(vm.subject.owningTeam);
			vm.misc.formDisabled = !vm.misc.isOwned || vm.subject.status === 'Closed';
		}

		if(vm.subject){
			setupMisc();
			if(vm.misc.isOwned){
				$scope.$watch('vm.subject', dirtyTracker.getWatcher('subjects'),true);
			}
		}

		/*
		$scope.$watch('vm.subject.severity', function(newVal){
			if( _(['NONE', 'SEVERITY_UNKNOWN']).includes(newVal) ){
				vm.misc.uninjured = true;
			} else {
				vm.misc.uninjured = false;
			}
		})
		*/
		

		if(!_.isArray(vm.inc.subjects)){
			vm.inc.subjects = [];
		}

		/*
		vm.newSubject = function(){
			vm.inc.subjects.push({ owningTeam:jsPageModel.team, misper: {} });
			$state.go("editor.subject", {subjectId:vm.inc.subjects.length -1 })
		}
		*/

		vm.delSubject = function(subject){
			if( _.has(subject, 'id')){
				confirm("Delete this subject") && confirm("Really?") &&
				$http.delete("/api/v1/incidents/" + jsPageModel.team + "/incident/" + vm.inc.main.id + "/subjects/" + subject.id).then(function(resp){
					_.remove(vm.inc.subjects, function(s){ return s.id == subject.id});
					PNotify.ok("Subject Deleted");
					$state.go("editor.main")
					dirtyTracker.deleteFrom('subjects', subject.id);
				});
			} else {
				_.remove(vm.inc.subjects, function(s){return subject == s});
				PNotify.ok("Subject Deleted");
				vm.inInAfterSave = true;
				$state.go("editor.main")
			}
		}

		vm.transferSubject = function(subject)
		{
			if(dirtyTracker.isDirty(subject) ||  _.isUndefined(subject.id))
			{
				PNotify.err("Save Subject First");
				return;
			}

			var subjectLabel = getSubjectLabel(subject)
			var teams = [].concat(vm.inc.main.owningTeams).concat(vm.inc.main.contributingTeams)
			
			var changeHolder = {
				newTeam: subject.owningTeam
			}

			var body = {
				tpl: 'xferOwningTeam.html',
				data: {
					teams: teams,
					subject: subject,
					subjectLabel: subjectLabel,
					change: changeHolder
				}
			}
			
			
			return confirmr.confirm("Transfer Subject",body, "OK, Transfer!", "Cancel", 'md')
					.then(function(resp){
						var newTeam = changeHolder.newTeam;
						debugger;
						if( (!newTeam) || newTeam == subject.owningTeam ){
							PNotify.warn("Team not changed")
							return;
						}
						else {
							$http.post("/api/v1/incidents/" + jsPageModel.team + "/incident/" + vm.inc.main.id + "/subject/" + subject.id + "/transfer/" + newTeam)
							.then(function(resp){
								PNotify.ok("Transferred Subject")
								vm.inInAfterSave = true; // allow state reloading without prompt;
								entities.reload(resp.data)
								$state.reload();
							})
						}

					}).catch(function(e){
						return false;
					})
		}

		vm.cloneSubject = function(subject)
		{
			if(dirtyTracker.isDirty(subject) ||  _.isUndefined(subject.id))
			{
				PNotify.err("Save Subject First");
				return;
			}

			entities.checkForOpenReport()
			.then(function(){
				var created = moment().format('YYYY-MM-DDTHH:mm:ssZZ');

				var copy = angular.copy(subject)

				copy.status = 'Open';
				copy.created = created;
				copy.owningTeam = jsPageModel.team
				copy.id = null;
				copy.personalData = null;

				_.each(copy.drugs, function(d){
					d.id = null;
				})
				_.each(copy.diagnoses, function(d){
					d.id = null;
				})


				vm.inc.subjects.push(copy)
				PNotify.ok("Subject Record Cloned")
				$state.go("editor.subject", {subjectId:vm.inc.subjects.length -1 })
			})
			
		}

		vm.newDiagnosis = function(subject){
			if(!_.isArray(subject.diagnoses)){ subject.diagnoses = []; }
			subject.diagnoses.push({  });
		}

		vm.delDiag = function(subject, diag, index){
			if(confirm("Really delete " + diag.type + " diagnosis?")){
				subject.diagnoses.splice(index, 1);
			}
		}

		vm.newDrug = function(subject){
			if(!_.isArray(subject.drugs)){ subject.drugs = []; }
			subject.drugs.push({  });
		}

		vm.delDrug = function(subject, drug, index){
			if(confirm("Really delete " + drug.name + "?")){
				subject.drugs.splice(index, 1);
			}
		}

		vm.onDiagSel = function(diag){
			if(diag.type == 'ENVIRONMENTAL'){
				diag.site = 'GEN_ENV';
				diag.orient = 'NA';
			}
			else if(diag.type == 'MEDICAL'){
				diag.site = 'GEN_MED';
				diag.orient = 'NA';
			}
			else if(diag.type == 'OTHER'){
				diag.site = 'GEN_OTHER';
				diag.orient = 'NA';
			}
			else if(diag.type == 'CONCS'){
				diag.site = 'GEN_CONCS';
				diag.orient = 'NA';
			}
			else {
				diag.site = null;
			}
		}

		vm.getSubjectLabel = getSubjectLabel

		vm.getRecordStatus = function(){
			if(vm.subject.status == 'Closed'){
				return "✔ Closed"
			}
			if(vm.subject.status == 'Open'){
				if(dirtyTracker.isDirty(vm.subject)){
					return 'Not Saved';
				}
				else {
					return "Open   ✔ Saved"
				}
			}
			
		}

		vm.getNextAction = function (){
			if(dirtyTracker.isDirty(vm.subject)){
				return 'save';
			}
			if(vm.subject.status == 'Open'){
				return 'close'
			}
			if(vm.subject.status == 'Closed'){
				return 'open'
			}
		}

		vm.editTagSet = function(entity, property, title, category){
			var model = _.get(entity,property) || [];
			tagpickr.pick(title, model, category)
			.then(function(newSet){
				_.set(entity,property,newSet);
			})
		}

		vm.revealPrivateDataField = function(subject, fieldName){
			$http.get("/api/v1/incidents/" + vm.team + "/incident/" + vm.inc.main.id + "/PID" + "/subject/" + subject.id + "/" + fieldName)
				.then(function(resp){
					subject[fieldName] = resp.data[fieldName];
					dirtyTracker.updateCachedProp('subjects', subject.id, fieldName, subject[fieldName] )
				})
		}

		this.uiCanExit = function uiCanExit(){
			if(vm.inInAfterSave){
				return true;
			}
			if(vm.subject){
				if( dirtyTracker.isDirty(vm.subject) ||  _.isUndefined(vm.subject.id) ) {
					return confirmr.confirm("Hang on there...","This subject record is not saved. Click OK to save or cancel to stay on this page", "OK, Save it!", "Cancel", 'md')
					.then(function(resp){
						return vm.save(true);
					}).catch(function(e){
						return false;
					})
				}
			}
		}

		/**
		 * returns boolean true if can save, otherwie false and a dialog or errors are shown. 
		 */
		vm.checkCanSave = function checkCanSave()
		{
			if($rootScope.forms.subj.$invalid){

				var fields = _($rootScope.forms.subj)
								.filter(function(p,k){ return !k.startsWith("$") && p.$invalid })
								.value()

				_(fields).each(function(p){
					if(p.$name.startsWith('diag')){
						p.name = 'Diagnosis ' + (+(p.$name.substring(7))+1) + ', ' + fieldNames[p.$name.substring(0,6)]
					}
					else if(p.$name.startsWith('drug')){
						p.name = 'Drug ' + (+(p.$name.substring(7))+1) + ', ' + fieldNames[p.$name.substring(0,6)]
					}
					else {
						p.name = fieldNames[p.$name] || p.$name
					}
				})
							
				var body = {
					tpl: 'onSaveErrors.html',
					data: {
						form: $rootScope.forms.subj,
						fields: fields
					}
				}

			
				confirmr.confirm("Cannot Save Subject Record",body, "OK", null, 'md')
				.then(function(resp){
					
				}).catch(function(e){/*noop*/})

				return false;
			}

			return true;
		}

		vm.doSave = function(subject, no_notify, onLeave){
			var update = {
				incidentId: vm.inc.main.id,
				subject: subject
			}

			return $http.put("/api/v1/incidents/" + jsPageModel.team + "/incident/" + vm.inc.main.id + "/subject" + (subject.id ? ('/' + subject.id):''), update )
			.then(function(resp){
				if(!no_notify){
					PNotify.ok("Saved Subject")
				}
				vm.inInAfterSave = true; // allow state reloading without prompt;
				entities.reload(resp.data)
				if(!onLeave){
					$state.reload();
				}
				
				return true;
			});
		}

		vm.save = function(onLeave){

			if(vm.misc.formDisabled){
				return
			}

			if($rootScope.forms.subj.$pristine && !dirtyTracker.isDirty(vm.subject) ){
				PNotify.warn("No Changes...")
				return;
			}

			if(!vm.checkCanSave()){
				return false;
			}
			else {
				return vm.doSave(vm.subject, false, onLeave||false )
			}
		}

		vm.validate = function validate()
		{
			var errors = subjectValidator.validateForClose(vm.subject);
			vm.invalids = null;

			if(!errors.length){
				return null;
			}
			else {
				vm.invalids = {}
				_(errors).each(function(p){
					if(p.$name.startsWith('diag')){
						p.name = 'Diagnosis ' + (+(p.$name.substring(7))+1) + ', ' + fieldNames[p.$name.substring(0,6)]
					}
					else if(p.$name.startsWith('drug')){
						p.name = 'Drug ' + (+(p.$name.substring(7))+1) + ', ' + fieldNames[p.$name.substring(0,6)]
					}
					else {
						p.name = fieldNames[p.$name] || p.$name
					}

					vm.invalids[p.$name] = true;
				})
				return errors;
			}
		}

		/*
		* true can save, false errors and modal is shown
		*/


		vm.checkCanClose = function checkCanClose()
		{
			var errors = vm.validate();

			if(!errors){ return true }

			var body = {
				tpl: 'onSaveErrors.html',
				data: {
					fields: errors
				}
			}
		
			confirmr.confirm("Cannot Close Subject Record",body, "OK", null, 'md')

			return false;
		}

		vm.close = function(ev){
			ev.target.blur()
			if(!vm.checkCanSave() || !vm.checkCanClose() ){
				return false;
			}
			else{
				vm.subject.status = 'Closed';
				vm.doSave(vm.subject, true, false);
				PNotify.ok("Subject Record Closed and Saved")
				return false
			}
		}

		vm.open = function(){

			entities.checkForOpenReport()
			.then(function(){
				vm.subject.status = 'Open';
				return vm.doSave(vm.subject, true, false)
				.then(function(){
					PNotify.ok("Subject Record Re-Opened")
				});
			})
		}

		var fieldNames = {
			type: 'Subject Type',
			gender: 'Gender',
			ageRange: 'Age Range',
			severity: 'Severity',
			personalData: 'Personal Information',
			diag_t: 'Type',
			diag_s: 'Site',
			diag_o: 'Orientation',
			diag_n: 'Nature',
			diag_x: 'Specfics',
			drug_i: 'Type',
			drug_d: 'Dose',
			drug_r: 'Route',
			drug_s: 'Source',
			drug_g: 'Given By',
			drug_n: 'Notes',
			injuryNotes: 'Injury & Treatment Notes'
		}
	}])

	.filter('subjDiagFilter', function(){

		function filterInjMedEnvOther(diag){
			return _.includes(['INJURY', 'ENVIRONMENTAL', 'MEDICAL', 'OTHER'], diag.type );
		}

		function filterConciousness(diag){
			return diag.type == 'STATUS' && diag.nature == 'CONCIOUSNESS';
		}

		function filterPainScore(diag){
			return diag.type == 'STATUS' && diag.nature == 'PAIN_SCORE';
		}

		return function(diags, type)
		{
			if(type == 'conc' ){
				return _.filter(diags, filterConciousness);
			} else if(type == 'pain') {
				return _.filter(diags, filterPainScore);
			}
			else {
				return _.filter(diags, filterInjMedEnvOther);
			}
		}
	})

	.controller('IncidentEditorAlertsController', ['$scope', 'entities', 'tagService', 'tagpickr', function incidentEditorAlertsController($scope, entities, tagService, tagpickr){
		var vm = this;
		vm.inc = entities.incident;
	}])

	.controller('IncidentEditorTeamReportController', 
				['$scope', 'entities', 'tagService', 'tagpickr', '$stateParams', '$state', '$http', 'Upload', '$uibModal', '$q','dirtyTracker', 'confirmr', '$rootScope', 'teamReportValidator',
				function incidentEditorTeamReportController($scope, entities, tagService, tagpickr, $stateParams, $state, $http, Upload, $uibModal, $q, dirtyTracker, confirmr, $rootScope, teamReportValidator){
		var vm = this;
		vm.inc = entities.incident;
		vm.team = entities.team;
		vm.features = entities.teamPrefs;
		vm.misc = {};

		vm.teamReport = _.find(vm.inc.reports, function(tr){ return tr.id == $stateParams.reportId});

		vm.misc.isOwned = entities.canEdit(vm.teamReport.team);
		vm.misc.canShow = entities.canRead(vm.teamReport.team);
		vm.misc.formDisabled = !vm.misc.isOwned || vm.teamReport.status === 'Closed';

		if(vm.misc.isOwned){
			$scope.$watch('vm.teamReport', dirtyTracker.getWatcher('reports'),true);
		}


		if(!_.isArray(vm.teamReport.attended)){
			vm.teamReport.attended = [];
		}

		vm.editTagSet = function(entity, property, title, category){
			var model = _.get(entity,property) || [];
			tagpickr.pick(title, model, category)
			.then(function(newSet){
				_.set(entity,property,newSet);
			})
		}

		vm.members = { ttl: -1, byGroup: null, byId:null }
		
		var getMembers = function(){
			if((!vm.members.byId) || vm.members.ttl < new Date().getTime() ){
				return $http.get("/api/v1/incidents/" + jsPageModel.team + "/members").then(function(resp){
					vm.members.byGroup = _(resp.data).groupBy("memberType").value();
					vm.members.byId = _(resp.data).keyBy("memberId").value();
					vm.members.ttl = new Date().getTime() + (1000*60); // 20seconds
					return vm.members;
				})
			}
			else {
				return vm.members;
			}
		}

		vm.addAnonMultiForAttendance = function(){
			vm.teamReport.attended.push({
				memberId: 'ANON_MULTI',
				persons: 1,
				startedAt: null, //vm.teamReport.startedAt,
				finishedAt: null, // vm.teamReport.finishedAt
			})
		}

		if( vm.features.FullAttendance === true )
		{
			getMembers(); // get members if in FULL mode
		}
		else 
		{
			if( ! vm.teamReport.attended ){
				vm.teamReport.attended = [];
			}
			if(vm.teamReport.attended.length == 0){
				vm.addAnonMultiForAttendance(); // if in summary mode, then add one if empty
			}
		}

		var addAttendingMembers = function(newMembers){
			_(newMembers).keys().forEach(function(m){
				vm.teamReport.attended.push({
					memberId: m,
					persons: 1,
					startedAt: null, //vm.teamReport.startedAt,
					finishedAt: null, // vm.teamReport.finishedAt
				})
			})
		}
		
		/* attendance */
		function chooseMembers(title){
			return $uibModal.open({
                templateUrl: '/gen/partials/incident/chooseMembers.modal.html', //'tagpickr.modal.html',
                size: 'md',
                controller: 'MemberChooserController',
                controllerAs: 'vm',
                resolve: {
                    members: function () {
                        return getMembers();
					},
					title: function(){ return title; }
                }
           }).result
		}

		vm.delAttendance = function(att, index){
			var name = "Unknown Rescuer"
			if(att.memberId == 'ANON_MULTI'){
				name = att.persons + " Rescuers";
			}
			else {
				var member = vm.members.byId[att.memberId]
				if(member){
					name = vm.members.byId[att.memberId].firstName + " " + vm.members.byId[att.memberId].lastName
				}
			}

			if(confirm("Delete Attendance for " + name + "?")){
				vm.teamReport.attended.splice(index, 1);
				//PNotify.ok("Deleted Attendance for " + name + "!");
			}
		}

		vm.addManualTimeEntry = function(att, field){
			att[field] = vm.teamReport[field];
		}

		vm.chooseMembersForAttendance = function(){
			chooseMembers("Select Attending Members").then(function(resp){
				addAttendingMembers(resp)
			},function(resp){})
		}

		vm.chooseMemberForTeamLeader = function(){
			chooseMembers("Select Team Leaders").then(function(resp){
				//console.log(resp);
				vm.teamReport.teamLeaders = _.uniq([].concat(vm.teamReport.teamLeaders, _.keys(resp) ))
				//console.log(vm.teamReport.teamLeaders);
			},function(resp){})
		}

		vm.removeTeamLeader = function(mid){
			_.remove(vm.teamReport.teamLeaders, function(it){ return it == mid});
		}

		vm.updateHours = function(m){
			if(m.finishedAt && m.startedAt){
				var hrs = _.round(moment(m.finishedAt).diff(moment(m.startedAt), 'hours', true), 2);
				m.hrs = hrs;
			}
		}
		
		vm.revealPrivateDataField = function(report, fieldName){
			$http.get("/api/v1/incidents/" + vm.team + "/incident/" + vm.inc.main.id + "/PID" + "/teamReport/" + report.id + "/" + fieldName)
				.then(function(resp){
					report[fieldName] = resp.data[fieldName];
					dirtyTracker.updateCachedProp('reports', report.id, fieldName, report[fieldName] )
				})
		}


		vm.showDuration = function(shortForm, start1, fin1 ){

			var start = start1 || vm.teamReport.startedAt
			var fin = fin1 || vm.teamReport.finishedAt

			if(start && fin){
				var durationP = moment.duration(moment(fin).diff(moment(start)));
				var mgrp = durationP.toString().match(/^P.*?(([0-9]*)D)?T?(([0-9]*)H)?(([0-9]*)M)?.*/)

				if(!mgrp){
					return 'error';
				}

				var duration = ''
				if( +mgrp[2] > 0){
					duration += mgrp[2]  + (shortForm ? 'd' : (' Day' + ( +mgrp[2] > 1 ? 's':'' ))) + ' '
				}
				if( +mgrp[4] > 0){
					duration += mgrp[4]  + (shortForm ? 'h' : (' Hour' + ( +mgrp[4] > 1 ? 's':'' ))) + ' '
				}
				if( +mgrp[6] > 0){
					duration += mgrp[6]  + (shortForm ? 'm' : (' Min' + ( +mgrp[6] > 1 ? 's':'' )))
				}
				return duration;
			} else {
				return false
			}
		}

		vm.calcTotalHours = function(){
			var total = 0;
			for(var i=0;i<vm.teamReport.attended.length;i++)
			{
				var att = vm.teamReport.attended[i];
				var start = att.startedAt || vm.teamReport.startedAt
				var fin = att.finishedAt || vm.teamReport.finishedAt
				if(start && fin){
					var durationP = moment.duration(moment(fin).diff(moment(start)));
					if(durationP){
						var att_hours = durationP.asHours();
						var att_res_hours = att_hours * att.persons;
						total += att_res_hours
					}
				}
			}
			return _.round(total, 2);
		}


		/* vehicles */


		vm.addVehicleActivity = function(){

			if( !_.isArray(vm.teamReport.vehicles) ){ vm.teamReport.vehicles = []; }

			var newVehAct = {
				vehicle: null,
				driver: null,
				tags: [],
				details: null
			};

			vm.editVehicleActivity(newVehAct, true);
		}

		vm.editVehicleActivity = function(activity, storeOnClose){
			return $uibModal.open({
                templateUrl: '/gen/partials/incident/editVehicleActivity.modal.html',
                size: 'md',
                controller: 'VehicleActivityController',
                controllerAs: 'vm',
                resolve: {
					activity: function(){ return activity },
                    members: function () {
                        return getMembers();
					},
					vehicles: function(){
						return vm.features['VehicleList']
					},
					tags: function(){
						return tagService.getCategory("VEHICLES")['Driving'];
					},
					saveText: function(){ return storeOnClose ? "Add" : "Update"}
                }
           }).result.then(function(){
               if(storeOnClose){
				   vm.teamReport.vehicles.push(activity) // otherwise its already in
				   PNotify.ok("Added: " + activity.vehicle + " for " + activity.driver);
			   }
			   else {PNotify.ok("Updated: " + activity.vehicle + " for " + activity.driver); }
           }, function(){
           })
		}

		vm.delVehicleActivity = function(activity, index){
			if(confirm("Delete " + activity.vehicle + " for " + activity.driver + "?")){
				vm.teamReport.vehicles.splice(index, 1);
				PNotify.ok("Deleted: " + activity.vehicle + " for " + activity.driver);
			}
		}


		vm.getRecordStatus = function(){
			if(vm.teamReport.status == 'Closed'){
				return "✔ Closed"
			}
			if(vm.teamReport.status == 'Open'){
				if(dirtyTracker.isDirty(vm.teamReport)){
					return 'Modified';
				}
				else {
					return "Open   ✔ Saved"
				}
			}
			
		}

		vm.getNextAction = function (){
			if(dirtyTracker.isDirty(vm.teamReport)){
				return 'save';
			}
			if(vm.teamReport.status == 'Open'){
				return 'close'
			}
			if(vm.teamReport.status == 'Closed'){
				return 'open'
			}
		}






		/* files */

		vm.memberFiles = [];
		vm.misc.newFile = { show: false };

		vm.upload = function(file, description){
			Upload.upload({
				url: "/files/" + jsPageModel.team + "/report/" + vm.teamReport.id,
				fields: {'comment': description}, // additional data to send
				file: file
			}).progress(function (evt) {
				var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
				//console.log('progress: ' + progressPercentage + '% ' + evt.config.file.name);
			}).success(function (data, status, headers, config) {
				//console.log('file ' + config.file.name + 'uploaded. Response: ' + data);
				vm.memberFiles.push(data)
				PNotify.ok("File Upload Success")
				vm.misc.newFile.file = null;
				vm.misc.newFile.comment = null;
				vm.misc.newFile.show = false;
			});
		}

		vm.deleteFile = function(fileId, index){
			if(confirm("Really delete file: " + vm.memberFiles[index].attachment.comment)) {
				$http.delete("/files/" + jsPageModel.team + "/report/" + vm.teamReport.id + "/file/" + fileId).then(function(resp){
					vm.memberFiles.splice(index,1)
					PNotify.ok("File deleted OK")
				})
			}
		}

		vm.canDeleteFile = function(file){
			return file.access === 'editable';
		}

		$http.get("/files/" + jsPageModel.team + "/report/" + vm.teamReport.id)
			.then(function(resp){
				vm.memberFiles = resp.data;
			})

		
		/* triggered by transition when the page is being exited */
		this.uiCanExit = function uiCanExit(){
				if(vm.inInAfterSave){
					return true;
				}
				if(vm.teamReport){
					if( dirtyTracker.isDirty(vm.teamReport) ||  _.isUndefined(vm.teamReport.id) ) {
						return confirmr.confirm("Hang on there...","This TeamReport record is not saved. Click OK to save or cancel to stay on this page", "OK, Save it!", "Cancel", 'md')
						.then(function(resp){
							return vm.save(true);
						}).catch(function(e){
							return false;
						})
					}
				}
			}

		/**
		 * returns boolean true if can save, otherwie false and a dialog or errors are shown. 
		 */
		vm.checkCanSave = function checkCanSave()
		{
			if($rootScope.forms.tr.$invalid){

				var fields = {};

				function findFields(form, top_prefix, list){

					var ffields = _(form)
								.filter(function(p,k){ return !k.startsWith("$") && p.$invalid })
								.value()

					_(ffields).each(function(p){
						var key = p.$name;
						if(p.$name.startsWith('att')){ // att_p_n
							p.name = 'Attendance ' + (+(p.$name.substring(6))+1) + ', ' + fieldNames[p.$name.substring(0,5)]
							fields[key] = p;
						}
						else if(p.$$parentForm.$name && p.$$parentForm.$name != top_prefix ){
							// then this is a subform
							var prefix = p.$name;
							findFields(p, (top_prefix ? top_prefix + '.' : '') + prefix, list);
						}
						else {
							if(top_prefix){
								key = (top_prefix + '.' +p.$name)
								p.name =  (fieldNames[key] || key)
							}
							else {
								p.name = fieldNames[p.$name] || p.$name
							}
							fields[key] = p;
						}
						
					})
				}

				findFields($rootScope.forms.tr, null, fields);

				

				
							
				var body = {
					tpl: 'onSaveErrorsTR.html',
					data: {
						form: $rootScope.forms.tr,
						fields: _.values(fields)
					}
				}

			
				confirmr.confirm("Cannot Save TeamReport Record",body, "OK", null, 'md')
				.then(function(resp){
					
				}).catch(function(e){/*noop*/})

				return false;
			}

			return true;
		}

		vm.doSave = function(report, no_notify, onLeave){
			var update = {
				incidentId: vm.inc.main.id,
				report: report
			}

			return $http.put("/api/v1/incidents/" + jsPageModel.team + "/incident/" + vm.inc.main.id + "/report" + (report.id ? ('/' + report.id):''), update )
			.then(function(resp){
				if(!no_notify){
					PNotify.ok("Saved TeamReport")
				}
				vm.inInAfterSave = true; // allow state reloading without prompt;
				entities.reload(resp.data)
				if(!onLeave){
					$state.reload();
				}
				
				return true;
			});
		}

		vm.save = function(onLeave){

			if(vm.misc.formDisabled){
				return
			}

			if($rootScope.forms.tr.$pristine && !dirtyTracker.isDirty(vm.teamReport)){
				PNotify.warn("No Changes...")
				return;
			}

			if(!vm.checkCanSave()){
				return false;
			}
			else {
				return vm.doSave(vm.teamReport, false, onLeave||false )
			}
		}

		// returns an error object, and sets vm.invalids to denote the fields that are not valid
		vm.validate = function validate()
		{
			var errors = teamReportValidator.validateForClose(vm.teamReport, entities.incident);
			vm.invalids = null;

			if(!errors.length){
				return null;
			}
			else {

				vm.invalids = {}

				_(errors).each(function(p){
					p.name = fieldNames[p.$name] || p.$name
					vm.invalids[p.$name] = true;
				})

				
							
				return errors;
			}
		}

		/*
		* true can save, false errors and modal is shown
		*/
		vm.checkCanClose = function checkCanClose()
		{
			var errors = vm.validate();

			if(!errors){ return true; }
							
			var body = {
				tpl: 'onSaveErrorsTR.html',
				data: {
					fields: errors
				}
			}
		
			confirmr.confirm("Cannot Close Subject Record",body, "OK", null, 'md')
			return false;
		}

		vm.close = function(){
			if(!vm.checkCanSave() || !vm.checkCanClose() ){
				return;
			}
			else{
				vm.teamReport.status = 'Closed';
				vm.doSave(vm.teamReport, true, false);
				PNotify.ok("TeamReport Record Closed and Saved")
			}
		}

		vm.open = function(){
			vm.teamReport.status = 'Open';
			vm.doSave(vm.teamReport, true, false);
			PNotify.ok("teamReport Record Re-Opened")
		}

		var fieldNames = {
			'deployment': 'Deployment Type', 
			'startedAt': 'Start Date/Time',
			'dtpick_startedAt.time': 'Start Time',
			'dtpick_startedAt.date': 'Start Date',
			'dtpick_finishedAt.time': 'Finish Time',
			'dtpick_finishedAt.date': 'Finish Date',
			'finishedAt': 'Finished Date/Time', 
			'teamLeaders': 'Team Leaders',
			'narrativeInc': 'Incident Background',
			'narrativeRes': 'Rescue Narrative',
			'attended': 'Team Attendance',
			'att_p': 'Persons'
		}
		
	}])

	.controller('MemberChooserController', 
					['$scope', 'entities', 'members', 'title', function memberChooserController($scope, entities, members,title ){
		var vm = this;
		vm.members = members;
		vm.title = title;
		vm.selected = {};
		vm.select = function(member){
			if(vm.selected[member.memberId]){
				delete vm.selected[member.memberId];
			}
			else {
				vm.selected[member.memberId] = member;
			}
		}
	}])


	.controller('VehicleActivityController', 
					['$scope', 'entities', 'activity','members', 'vehicles', 'tags','saveText',
					function vehicleActivityController($scope, entities, origActivity, members, vehicles, tags, saveText ){
		var vm = this;
		vm.activity = angular.copy(origActivity);
		vm.members = members;
		vm.vehicles = vehicles;
		vm.tags = tags
		vm.checked = {}
		vm.misc = {
			saveText: saveText
		}
		// set the display based on tags
		_.each(vm.activity.tags, function(t){
			vm.checked[t] = true;
		})

		vm.driverNotesRequired = function(){
			return _(vm.checked).values().some(function(it){ return !!(it) });
		}

		// set the tags based on display
		vm.onClose = function(){
			var tagSet = []
			_.each(vm.checked, function(value,key){
				if(!!(value)){
					tagSet.push(key);
				}
			});

			_.merge(origActivity, _.pick(vm.activity, ['vehicle', 'driver', 'details']) )
			origActivity.tags = tagSet;

			$scope.$close()
		}

	}])

	.component('drugPicker', {
		require: {
			ngModelCtrl: 'ngModel',
		},
		bindings: {
			ngModel: "="
		}
		, template: '<div>' +
					//'<input class="form-control" style="width:25%" ng-model="$ctrl.drug" />' + 
					'<input class="form-control" style="width:20%; display:inline-block;" ng-model="$ctrl.dose" />'	+
					'<input class="form-control" style="width:20%; display:inline-block;" ng-model="$ctrl.route" />' +
					'<input class="form-control" style="width:20%; display:inline-block;" ng-model="$ctrl.source" />' +
					'<input class="form-control" style="width:20%; display:inline-block;" ng-model="$ctrl.giver" />' + 
					'<input class="form-control" style="width:20%; display:inline-block;" ng-model="$ctrl.notes" />' + '</div>'
		,
		controller: ['$scope', function drugPicker($scope){
			var $ctrl = this;
			this.$onInit = function(){
				if( typeof $ctrl.ngModel === 'string')
				{
					var parts = $ctrl.ngModel.split("|");
					if(parts.length == 5){
						$ctrl.drug = parts[0];
						$ctrl.dose = parts[1];
						$ctrl.source = parts[2];
						$ctrl.giver = parts[3];
						$ctrl.notes = parts[4];
					}
					else {
						$ctrl.drug = '';
						$ctrl.dose = '';
						$ctrl.source = '';
						$ctrl.giver = '';
						$ctrl.notes = ngModel;
					}
				}

				function escape(input){
					return (input && input.replaceAll("|", "_")) || ''
				}

				function combine(){
					escape($ctrl.drug) + "|" + 
					escape($ctrl.dose) + "|" + 
					escape($ctrl.source) + "|" + 
					escape($ctrl.giver) + "|" + 
					escape($ctrl.notes) 
				}

				$scope.$watchGroup(['$ctrl.drug','$ctrl.dose','$ctrl.source','$ctrl.giver','$ctrl.notes']
									, function(newVal, oldVal, scope){
										$ctrl.ngModel = combine();
									})					
			}
		}]

	})

	.component('dateTimePickr',{
		require: {
			ngModelCtrl: 'ngModel',
		  },
		bindings: {
			ngModel: "=",
			noIcons:'<',
			onUpdate: '&',
			name: '@name',
			myDisabled: '=ngDisabled', 
		}
		, template: ['$element', '$attrs', function($element, $attrs){
					if($attrs.small){
						return '<div ng-form name="{{\'dtpick_\' + $ctrl.name}}" class="dt-group" style="display:inline-block">' + 
							     '<div class="input-group">'+
									'<a class="input-group-addon" ng-if="!$ctrl.myDisabled" pick-a-date="$ctrl.date" pick-a-date-options="{ format: \'ddd, dd mmm yyyy\' }">{{$ctrl.date | moment:"DD/MM"}}</a>'+
									'<span class="input-group-addon" ng-if="$ctrl.myDisabled">{{$ctrl.date | moment:"DD/MM"}}</span>' +
									'<input ng-model="$ctrl.time" name="time" type="text" ng-disabled="$ctrl.myDisabled" class="form-control dt-time" ng-style="$ctrl.invalidClr(\'time\')" placeholder="hh:mm" ng-pattern="$ctrl.timePattern">'+
								  '</div>' +
								'</div>'
					}
					else 
					{
						return '<div ng-form name="{{\'dtpick_\' + $ctrl.name}}" class="input-group dt-group">' + 
									'<span ng-hide="$ctrl.noIcons" class="input-group-addon"><i class="fa fa-calendar"></i></span>'+
									'<input ng-disabled="$ctrl.myDisabled" type="text" name="date" class="form-control dt-date" style="width:67%; background-color:#fff; padding: 8px 8px;" pick-a-date="$ctrl.date" pick-a-date-options="{ format: \'ddd, dd mmm \\\'yy\' }" placeholder="Click to set &hellip;">'+
									'<input ng-disabled="$ctrl.myDisabled" ng-model="$ctrl.time" name="time" type="text" class="form-control dt-time" ng-style="$ctrl.invalidClr(\'time\')" style="width:33%;border-left:none;padding: 8px 8px;" placeholder="hh:mm" ng-pattern="$ctrl.timePattern" > '+
									'<span ng-hide="$ctrl.noIcons" class="input-group-addon"><i class="fa fa-clock-o"></i></span>'+
								'</div>'
					}
		}]
		, controller: ['$scope', function dateTimePickrCtrl($scope){
			
			var $ctrl = this;

			$ctrl.asMoment = null;

			this.timePattern = /^([01]?[0-9]|2[0-3]):?([0-5][0-9])$/

			this.invalidClr = function(dtelem){
				return { 'background-color': $scope['dtpick_' + $ctrl.name][dtelem].$invalid ? '#dea3a3':'#fff' }
			}

			this.$onInit = function(){

				$ctrl.asMoment = $ctrl.ngModel && moment($ctrl.ngModel) || null;
				$ctrl.date = $ctrl.asMoment && $ctrl.asMoment.toDate() || null;
				$ctrl.time = null;

				if($ctrl.asMoment)
				{
					if( $ctrl.asMoment.format("HH:mm:ss") == '00:00:30'){
						//time has not been explicity set.
						// noop - leave as null
					}
					else {
						$ctrl.time = $ctrl.asMoment.format("HH:mm");
					}
				}
				


				

				$scope.$watch(function(){ return $ctrl.date && $ctrl.date.getTime() }, function(){

					if(!$ctrl.date){ return }

					var newDateOpts = {
						'year': $ctrl.date.getFullYear(),
						'month': $ctrl.date.getMonth(),
						'date': $ctrl.date.getDate()
					}

					if(!$ctrl.asMoment){
						$ctrl.asMoment = moment('1970-01-01 00:00:30');
					}

					$ctrl.asMoment.set(newDateOpts)
					$ctrl.ngModelCtrl.$setViewValue($ctrl.asMoment.format())

					$ctrl.onUpdate()
				})

				$scope.$watch(function(){ return $ctrl.time }, function(newTime){

					if(!newTime){ return; }
					if(!$ctrl.timePattern.test(newTime)){ return; }

					var match = $ctrl.timePattern.exec(newTime);

					var newTimeOpts = {
						'hour': match[1],
						'minute': match[2],
						'second':0
					}

					if(!$ctrl.asMoment){
						$ctrl.asMoment = moment();
					}

					$ctrl.asMoment.set(newTimeOpts);
					$ctrl.ngModelCtrl.$setViewValue($ctrl.asMoment.format())

					$ctrl.onUpdate()
				})
			}


		}]
	})

	function getSubjectLabel(subject){
		var label = "New Subject";
		if(subject.type == "CASUALTY"){ label = "Casualty" }
		else if(subject.type == "MISPER"){ label = "MisPer" }
		else if(subject.type == "INFORMANT"){ label = "Informant" }
		else if(subject.type == "OTHER") { label = "Other" }
		
		if(subject.gender || subject.ageRange){
			label += " ("
			if(subject.gender){ label += subject.gender.charAt(0); }
			else {
				label += '?'
			}
			if( ! _.isNil(subject.ageRange) ){ 
				switch(parseInt(subject.ageRange)){
					case -1: { label += 'U' ;break; }
					case 0: { label += ',Ifnt' ;break; }
					case 1: { label += ',Tdlr' ;break; }
					case 3: { label += ',Child' ;break; }
					case 13: { label += ',Teen' ;break; }
					case 18: { label += '<24' ;break; }
					default: label += (subject.ageRange)
				}
			}
			else {
				label += ',?'
			}
			
			label += ")"
		}

		return label
	}


	var diagTree = {
		diagnoses: {
			CONCS: {
				display: "Level of Consciousness",
				sites: {
					"General": ['GEN_CONCS']
				}
			},
			INJURY: {
				display: "Injury",
				sites: {
					"Head": [ "HEAD", "FACE" ],
					"Back": ['BACK', "SPINE", "CSPINE"],
					"Torso": ['CHEST', 'ABDOMEN', "PELVIS", "BUTTOCK"],
					"Upper Limb": ['CLAVICLE', 'SHOULDER', 'UPPER_ARM', 'ELBOW', 'FOREARM', 'WRIST', 'HAND'],
					"Lower Limb": ['THIGH', 'KNEE', 'LOWER_LEG', 'ANKLE', 'FOOT'],
					"General": ['WHOLE_BODY']
				}
			},
			MEDICAL: {
				display: "Illness",
				sites: {
					"General": ['GEN_MED']
				}
			},
			ENVIRONMENTAL: {
				display: "Environmental",
				sites: {
					"General": ['GEN_ENV']
				}
			},
			OTHER: {
				display: "Other",
				sites: {
					"General": ['GEN_OTHER']
				}
			}
		},
		sites: {
			HEAD: { id:"HEAD", display:"Head", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION', 'INTERNAL'], orients:['LEFT','RIGHT','TOP','BACK'] },
			FACE: { id:"FACE", display:"Face", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT'], orients:['LEFT','RIGHT','FRONT'] },
			BACK: { id:"BACK", display:"Back" , natures:['GRAZE', 'BITE', 'BRUISE', 'BURN','CUT', 'INTERNAL'], orients:['LEFT','RIGHT'] },
			SPINE: { id:"SPINE", display:"Spine (Th/Lu/Sa/Co)" , natures:[ 'BRUISE', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'INTERNAL'], specifics:['NEURO_NORMAL', 'NEURO_PARAL'] },
			CSPINE: { id:"CSPINE", display:"C-Spine" , natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT','INTERNAL'], specifics:['NEURO_NORMAL', 'NEURO_PARAL'] },
			CHEST: { id:"CHEST", display:"Chest" , natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'INTERNAL'], orients:['LEFT','RIGHT'] },
			ABDOMEN: { id:"ABDOMEN", display:"Abdomen", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'CUT', 'AMPUTATION', 'INTERNAL'], orients:['LEFT','RIGHT'] },
			PELVIS: { id:"PELVIS", display:"Pelvis", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'INTERNAL'], orients:['LEFT','RIGHT'] },
			BUTTOCK: { id:"BUTTOCK", display:"Buttock", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'CUT', 'INTERNAL'], orients:['LEFT','RIGHT'] },
			CLAVICLE: { id:"CLAVICLE", display:"Clavicle", natures:[ 'FRAC_OPEN', 'FRAC_CLOSED'], orients:['LEFT','RIGHT'] },
			SHOULDER: { id:"SHOULDER", display:"Shoulder", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION','SPRAIN'], orients:['LEFT','RIGHT'] },
			UPPER_ARM: { id:"UPPER_ARM", display:"Upper Arm", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION'], orients:['LEFT','RIGHT'] },
			ELBOW: { id:"ELBOW", display:"Elbow", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION','SPRAIN'], orients:['LEFT','RIGHT'] },
			FOREARM: { id:"FOREARM", display:"Forearm", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION'], orients:['LEFT','RIGHT'] },
			WRIST: { id:"WRIST", display:"Wrist", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION','SPRAIN'], orients:['LEFT','RIGHT'] },
			HAND: { id:"HAND", display:"Hand", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION'], orients:['LEFT','RIGHT'] },
			THIGH: { id:"THIGH", display:"Thigh", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION'], orients:['LEFT','RIGHT']},
			KNEE: { id:"KNEE", display:"Knee", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION', 'SPRAIN'], orients:['LEFT','RIGHT'] },
			LOWER_LEG: { id:"LOWER_LEG", display:"Lower Leg", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION'], orients:['LEFT','RIGHT'] },
			ANKLE: { id:"ANKLE", display:"Ankle", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION','SPRAIN'], orients:['LEFT','RIGHT'] },
			FOOT: { id:"FOOT", display:"Foot", natures:['GRAZE', 'BITE', 'BRUISE', 'BURN', 'DISLOCATION', 'FRAC_OPEN', 'FRAC_CLOSED', 'CUT', 'AMPUTATION'], orients:['LEFT','RIGHT'] },
			WHOLE_BODY: { id:"WHOLE_BODY", display:"Whole Body", natures:['POLY_TRAUMA'], orients:['NA'] },
			GEN_MED: { id:"GEN_MED", display:"General", natures:['CHEST', 'BREATHING', 'ALLERGY', 'DIABETES', 'FIT', 'STROKE', 'COLLAPSE', 'ABDO_PAIN', 'CRAMP', 'EXHAUSTED'], orients:['NA'] },
			GEN_ENV: { id:"GEN_ENV", display:"General", natures:['HYPOTH', 'HEAT', 'DROWN', 'LIGHTNING', 'AVALANCHED'], orients:['NA']},
			GEN_OTHER: { id:"GEN_OTHER", display:"General", natures:[ 'SUICIDE', 'SUBS_ABUSE', 'SELF_HARM' ], orients:['NA']},
			GEN_CONCS: { id:"GEN_CONCS", display:"General", natures:[ 'A', 'V', 'P', 'U', 'GCS3', 'GCS4', 'GCS5', 'GCS6', 'GCS7', 'GCS8','GCS9', 'GCS10', 'GCS11', 'GCS12', 'GCS13', 'GCS14', 'GCS15'  ], orients:['NA']}
		},
		orientations: {
			LEFT: {id:'LEFT', display:'Left'},
			RIGHT: {id:'RIGHT', display:'Right'},
			TOP: {id:'TOP', display:'Top'},
			BACK: {id:'BACK', display:'Back'},
			FRONT: {id:'FRONT', display:'Front'},
			NA: { id:'NA', display:'N/A' }
		},
		natures: {
			AMPUTATION: { id:"AMPUTATION", display:"Amputation" },
			BITE: { id:"BITE", display:"Bite" },
			BRUISE: { id:"BRUISE", display:"Bruise" },
			BURN: { id:"BURN", display:"Burn" },
			CUT: { id:"CUT", display:"Cuts/Lacerations" },
			DISLOCATION: { id:"DISLOCATION", display:"Dislocation" },
			FRAC_CLOSED: { id:"FRAC_CLOSED", display:"Fracture (Closed)" },
			FRAC_OPEN: { id:"FRAC_OPEN", display:"Fracture (Open)" },
			GRAZE: { id:"GRAZE", display:"Graze / Abrasion" },
			INTERNAL: { id:"INTERNAL", display:"Internal Injuries" },
			SPRAIN: { id:'SPRAIN', display:"Sprain/Strain" },
			POLY_TRAUMA: { id:'POLY_TRAUMA', display:'Poly-Trauma'},
			
			CHEST: { id:"CHEST", display:"Chest/Cardiac", specifics:['ANGINA', 'MI', 'CHEST_PAIN','PNEUMOTHORAX' ] },
			BREATHING: { id:"BREATHING", display:"Breathing", specifics:['ASTHMA', 'BREATHING_OTHER']},
			ALLERGY: { id:"ALLERGY", display:"Allergy", specifics:['ALGY_MILD', 'ANAPHYLAXIS'] },
			DIABETES: { id:"DIABETES", display:"Diabetes" },
			FIT: { id:"FIT", display:"Fit/Seizure" },
			STROKE: { id:"STROKE", display:"Stroke" },
			COLLAPSE: { id:"COLLAPSE", display:"Collapse" },
			ABDO_PAIN: { id:"ABDO_PAIN", display:"Abdominal Pain" },
			CRAMP: { id:"CRAMP", display:"Cramp" },
			EXHAUSTED: { id:"EXHAUSTED", display:"Exhausted" },
			
			

			DROWN: { id:"DROWN", display:"Drowning" },
			HEAT: { id:"HEAT", display:"Heat Related Illness" },
			HYPOTH: { id:"HYPOTH", display:"Hypothermia", specifics:["HYPOTH_1","HYPOTH_2","HYPOTH_3","HYPOTH_4"] },
			LIGHTNING: { id:"LIGHTNING", display:"Lightning Strike" },
			AVALANCHED: {id: "AVALANCHED", display:"Avalanched", specifics:["AVALANCHED_PART","AVALANCHED_FULL"] },

			SUICIDE: { id:"SUICIDE", display:"Suicide" },
			SUBS_ABUSE: { id:"SUBS_ABUSE", display:"Substance Abuse" },
			SELF_HARM: { id: "SELF_HARM", display:"Self Harm"}

	
			,A: { id:"A", display:"AVPU - Alert"}
			,V: { id:"V", display:"AVPU - Voice"}
			,P: { id:"P", display:"AVPU - Pain"}
			,U: { id:"U", display:"AVPU - Unresponsive"}
			
			,GCS3: {id: "GCS3", display:"GCS - 3"}
			,GCS4: {id: "GCS4", display:"GCS - 4"}
			,GCS5: {id: "GCS5", display:"GCS - 5"}
			,GCS6: {id: "GCS6", display:"GCS - 6"}
			,GCS7: {id: "GCS7", display:"GCS - 7"}
			,GCS8: {id: "GCS8", display:"GCS - 8"}
			,GCS9: {id: "GCS9", display:"GCS - 9"}
			,GCS10: {id: "GCS10", display:"GCS - 10"}
			,GCS11: {id: "GCS11", display:"GCS - 11"}
			,GCS12: {id: "GCS12", display:"GCS - 12"}
			,GCS13: {id: "GCS13", display:"GCS - 13"}
			,GCS14: {id: "GCS14", display:"GCS - 14"}
			,GCS15: {id: "GCS15", display:"GCS - 15"}

		},
		specifics: {
			HYPOTH_1: { id:"HYPOTH_1", display:"Stage 1: Mild" },
			HYPOTH_2: { id:"HYPOTH_2", display:"Stage 2: Moderate" },
			HYPOTH_3: { id:"HYPOTH_3", display:"Stage 3: Severe" },
			HYPOTH_4: { id:"HYPOTH_4", display:"State 4: No Signs" },
			ALGY_MILD: { id:"ALGY_MILD", display:"Mild Allergy" },
			ANAPHYLAXIS: { id:"ANAPHYLAXIS", display:"Anaphylaxis" },
			ANGINA: { id:"ANGINA", display:"Angina" },
			ASTHMA: { id:"ASTHMA", display:"Asthma" },
			BREATHING_OTHER: { id:"BREATHING_OTHER", display:"Other" },
			CHEST_PAIN: { id:"CHEST_PAIN", display:"Chest Pain" },
			MI: { id:"MI", display:"Myocardial Infarction" },
			NEURO_NORMAL: { id:"NEURO_NORMAL", display:"Normal Neurology" },
			NEURO_PARAL: { id:"NEURO_PARAL", display:"Paralysis" },
			PNEUMOTHORAX: { id:"PNEUMOTHORAX", display:"Pneumothorax" },
			AVALANCHED_PART: { id: "AVALANCHED_PART", display:"Partial Burial"},
			AVALANCHED_FULL: { id: "AVALANCHED_FULL", display:"Full Burial"}

			,CONC_OBS_OVERALL: { id: "CONC_OBS_OVERALL", display:"Overall Observation"}
			,CONC_OBS_INIT: { id: "CONC_OBS_INIT", display:"Initial Observation"}
			,CONC_OBS_FINAL: { id: "CONC_OBS_FINAL", display:"Final Observation"}
		}
	};


	var drugsList = _([

		{"id": "GLUCOGEL", "name": "Glucogel (Oral Glucose)", "group":"Group 1"},
		{"id": "PARACETAMOL", "name": "Paracetamol", "group":"Group 1"},
		{"id": "ASPIRIN", "name": "Aspirin", "group":"Group 1"},
		{"id": "IBUPROFEN", "name": "Ibuprofen", "group":"Group 1"},

		{"id":"OXYGEN", "name":"Oxygen", "group":"Group 2 - Gases"},
		{"id":"ENTONOX", "name":"Entonox", "group":"Group 2 - Gases"},
		
		{"id": "SALBUTAMOL", "name": "Salbutamol", "group":"Group 2 - Breathing"},
		{"id": "IPRATROPIUM", "name": "Ipratropium Bromide (Atrovent)", "group":"Group 2 - Breathing"},
		
		{"id": "ADRENALINE", "name": "Adrenaline (EPINEPHRINE)", "group":"Group 2 - Misc"},
		{"id": "BUCCASTEM", "name": "Buccastem (Prochlorperazine)", "group":"Group 2 - Misc"},
		{"id": "GTN", "name": "Glyceryl Trinitrate (GTN)", "group":"Group 2 - Misc"},
		{"id": "CEFUROXIME", "name": "Cefuroxime", "group":"Group 2 - Misc"},
		{"id": "MIDAZOLAM", "name": "Midazolam", "group":"Group 2 - Misc"},
		{"id": "NALOXONE", "name": "Naloxone", "group":"Group 2 - Misc"},
		
		{"id": "MORPHINE", "name": "Morphine", "group":"Group 2 - Analgesia"},
		


		{"id": "DRUG_OTHER_SELF", "name": "Casualty's Own Meds", "group":"Other"},
		{"id": "DRUG_OTHER_AGENCY", "name": "Drugs From Another Agency", "group":"Other"},
		{"id": "DRUG_OTHER", "name": "Other Drugs or Sources", "group":"Other"},

		{"id": "ANTIBIOTIC", "name": "Antibiotic Injection", "group":"Group 3"},
		{"id": "ANTIEMETIC", "name": "Antiemetic Injection", "group":"Group 3"},
		{"id": "CHLORPHENAMINE", "name": "Chlorphenamine Injection", "group":"Group 3"},
		{"id": "HYDROCORTISONE", "name": "Hydrocortisone Injection", "group":"Group 3"},
		{"id": "INDIAMORPHINE", "name": "Intra-Nasal Diamorphone", "group":"Group 3"},
		{"id": "FENTANYL", "name": "Fentanyl Lozenge", "group":"Group 3"},
		{"id": "LOCALANAESTHESIA", "name": "Local Anaesthesia (e.g. Lignocaine)", "group":"Group 3"},
		
		{"id": "TRANEXAMICACID", "name": "Tranexamic Acid", "group":"Group 3"}
		 
		

	]).groupBy('group').value();




})();
