'use strict'
;(function(){
	
	/* Glue for starting AngularJS with custom modules */
	angular.runINCWEB = function(deps){
		
		var modules = ['angularModalService', 'ngSanitize','ui.select','sbHttpConfig','sbCommon'
			,'ui.bootstrap'
			,'ngAside'
			,'ng-confirmr'
			,'ngMessages'
			,'blockUI'
			,'ngFileUpload'
			,'sbTemplateCache'
			,'ng-tagpickr'
			,'sbMoment'
			,'pickadate'
			,'supportr'
			,'incValidators'
		];
		if(angular.isArray(deps)){
			modules = modules.concat(deps);
		}
		else if(angular.isString(deps)){
			modules.push(deps);
		}
		var incweb = angular.module('inc-web', modules )
		
	};
	
	
	
	angular.module('sbCommon', ['blockUI'])

	.config(['$sceDelegateProvider', function($sceDelegateProvider){
		$sceDelegateProvider.resourceUrlWhitelist([
			// Allow same origin resource loads.
			'self',
			// Allow loading from our help domain.
			'http://wiki.mountain.rescue.org.uk/**',
			'https://wiki.mountain.rescue.org.uk/**',
			"http://localhost:8000/**"
		  ]);
	}])

	.run(['$rootScope', '$http', function($rootScope, $http){
		$rootScope.url = INCWEB.url;

		$rootScope.$on("$routeChangeSuccess", function (event, currentRoute, previousRoute) {
			$( '[canvas="container"]' ).animate( {
				scrollTop: 0
			}, 150 );
		});

		$rootScope.getVersion = function(){
			$http.get( INCWEB.url('/version')).then(function(resp){
				$rootScope.appVersion =  resp.data
			})
		}

	}])

	.run(['$rootScope', '$aside', '$location', function ($rootScope, $aside, $location) 
	{
		function openHelp(topic)
		{
			if(!topic){
				return;
			}
			
			var aside = $aside.open({
				templateUrl: '/gen/partials/common/help-iframe.html',
				placement: 'left',
				size: 'md',
				controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
					$scope.content = "/docs/" + topic
					var label = topic.replace(".html?embed", "").replace("#", "&nbsp;&gt;&nbsp;");
					$scope.topic = decodeURIComponent(label);
					$scope.asideClose = function($event) {
						$uibModalInstance.close();
						$event.stopPropagation();
					};
				}]
			});
		}
		$rootScope.openHelp = openHelp
		$rootScope.openHelp.module = ""
	
	}])

	.run(['$rootScope', function ($rootScope) 
	{
		if( window.jsPageModel && typeof window.jsPageModel.perms === 'object' ){
			$rootScope.perms = window.jsPageModel.perms;
		}
	}])
	
	.config(['blockUIConfig', function(blockUIConfig) {

		// Change the default overlay message
		blockUIConfig.message = 'Please Wait...';

		// Change the default delay to 100ms before the blocking is visible
		blockUIConfig.delay = 0;

		// https://github.com/McNull/angular-block-ui#autoinjectbodyblock
		blockUIConfig.autoInjectBodyBlock = false;

		// Tell the blockUI service to ignore certain requests
		blockUIConfig.requestFilter = function(config) {
			if(config.noBlock) {
				return false; // ... don't block it.
			}
		};

		}])

	.directive('convertToNumber', function() {
		return {
			require: 'ngModel',
			link: function(scope, element, attrs, ngModel) {
			ngModel.$parsers.push(function(val) {
				return parseInt(val, 10);
			});
			ngModel.$formatters.push(function(val) {
				return '' + val;
			});
			}
		};
		})
		
	.filter('uniqCount', function() {
		var filter = function(list, property) {
			if(!list || list.length == 0){
				return 0;
			}
			return _(list).map(property).uniq().value().length
		}
		
		filter.$stateful = true;
		return filter;
	  })
	
	  .filter('sumProp', function() {
		var filter = function(list, property) {
			if(!list || list.length == 0){
				return 0;
			}
			var list = _(list);
			return _.round(list.sumBy(property), 2);
		}
		
		filter.$stateful = true;
		return filter;
	  })

	  .directive('ih', ['$rootScope', function($rootScope) {
			return {
				restrict: 'A',
				transclude: true,
				scope: { ih:'@' },
				template: '<ng-transclude></ng-transclude>&nbsp;<i class="fa fa-question-circle text-muted" ng-click="openHelp(ih)"></i>',
				link: function(scope){
					scope.openHelp = function(ih){
						$rootScope.openHelp(ih);
					}
				}
			}
		}])

		.component('ihLink', {
			bindings: {
				topic: '@'
			},
			template: '<a ng-click="$ctrl.openHelp(topic)">Help&nbsp;<i class="fa fa-question-circle text-muted"></i></a>',
			controller: ['$rootScope', function($rootScope){
				this.openHelp = function(ih){
					$rootScope.openHelp(ih);
				}
			}],
		})

		// extend ng-click to lose focus
		.directive('ngClick', [ function() {
			return {
			  restrict: 'A',
			  link: function(scope, element, attr) {
				element.bind('click', function() {
					this.blur();
					this.hideFocus = true;
				})
			  }
			}
		}])

		// unused
		.directive('reqWarn', ['$rootScope', function($rootScope) {
			return {
				restrict: 'E',
				scope: true,
				template: '<i ng-if="vm.invalids[key]" class="req-warn fa fa-exclamation-triangle text-danger" onclick="alert(\'This field is required.\')"></i>',
				link: function(scope, element, attr){
					scope.key = attr.key
				}
			}
		}])

		/*
		.directive('ih-link', ['$rootScope', function($rootScope) {
			return {
				restrict: 'E', 
				scope: { topic:'@' },
				template: '<a ng-click="openHelp(topic)">Help&nbsp;<i class="fa fa-question-circle text-muted"></i></a>',
				link: function(scope){
					
				}
			}
		}])
		*/
	  
	  ;
	
})();
