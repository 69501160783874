'use strict'
;(function boot(){

    angular.module('incValidators', [])
    
    .factory('subjectValidator', [function subjectValidator()
    {
        var conf = {
            
            OTHER: {
                required: ['personalData']
            },
            INFORMANT: {
                required: ['personalData']
            },
            CASUALTY: {
                required: ['gender', 'type', 'ageRange', 'severity', 'personalData', 'injuryNotes'],
                warning: []
            },
            MISPER: {
                required: ['gender', 'type', 'ageRange', 'severity', 'personalData', 'injuryNotes' ],
                warning: []
            }
        }

        /**
         * Return empty array if OK or Errors object array for ng-messages
         */
        function validateForClose(subject)
        {
            var type = subject.type;

            if(!type){
                return[{ $name: type, "$error": { required: true }}];
            }

            var type_conf = conf[type];
            if(!type_conf){
                return [{ $name: type, "$error": { invalid: true }}];
            }

            var errors = [];
            _.each(type_conf.required, function(field){
                var val = _.get(subject, field);
                if( _.isNil(val ) || (_.isString(val) && val.length ==0 )  ) {
                    errors.push({ $name: field, "$error": { required: true }});
                }
            })
            return errors;
        }

        return { 
            validateForClose:validateForClose
        };

    }])


    .factory('teamReportValidator', ['entities', function teamReportValidator(entities)
        {
            var conf = {
                
                report: {
                    required: ['deployment', 
                                'startedAt', 'finishedAt', 
                                'teamLeaders',
                                'narrativeInc',
                                'narrativeRes',
                            ]
                }
            }
    
            /**
             * Return empty array if OK or Errors object array for ng-messages
             */
            function validateForClose(report, incident)
            {
                var type_conf = conf['report'];
                
                var errors = [];
                _.each(type_conf.required, function(field){
                    var val = _.get(report, field);
                    if( _.isNil(val) || ( _.isString(val) && val.length == 0 ) ) {
                        errors.push({ $name: field, "$error": { required: true }});
                    }
                })

                _(['startedAt', 'finishedAt']).each(function(dt){
                    if( !report[dt] || moment(report[dt]).format("HH:mm:ss") == '00:00:30' ){
                        // time has not been explicity set.
                        errors.push({ $name: 'dtpick_' + dt + '.time', "$error": { required: true }});
                    }
                })

                if( report.startedAt && report.finishedAt ){
                    if( moment(report.finishedAt).isBefore(moment(report.startedAt)) ){
                        errors.push({ $name: 'finishedAt', "$error": { 'finish_before_start': true }});
                    }
                }

                if( !report.attended || !report.attended.length){
                    errors.push({ $name: 'attended', "$error": { required: true }});
                }

                _.each( report.attended, function(att){
                    if( (!_.isNumber(att.persons)) || att.persons < 1 ){
                        errors.push({ $name: 'attended', "$error": { 'invalid_persons_number': true }});
                    }
                })

                _(incident.subjects).each(function(s){
                    if(s.owningTeam == entities.team && s.status != 'Closed'){
                        errors.push({ $name: s.type, "$error": { 'subject_not_closed': true }});
                    }
                })

                if(entities.canEdit(incident.main.owningTeams) && incident.main.status != 'Closed'){
                    errors.push({ $name: 'main', "$error": { 'main_not_closed': true }});
                }

                

                return errors;
            }
    
            return { 
                validateForClose:validateForClose
            };
    
        }])

        .factory('mainDetailsValidator', ['entities', function mainDetailsValidator(entities)
            {
                var conf = {
                    main: {
                        required: ['title', 
                                    'reason', 'activity', 
                                    'search',
                                    'rescue',
                                    'location',
                                    'partySize'
                                ]
                    },
                }
        
                /**
                 * Return empty array if OK or Errors object array for ng-messages
                 */
                function validateForClose(main)
                {
                    var type_conf = conf['main'];
                    
                    var errors = [];
                    _.each(type_conf.required, function(field){
                        if( _.isNil( _.get(main, field)) ) {
                            errors.push({ $name: field, "$error": { required: true }});
                        }
                    })
                    return errors;
                }
        
                return { 
                    validateForClose:validateForClose
                };
        
            }])
    




})();