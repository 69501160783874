(function() {
  'use strict';

  angular
    .module('ng-tagpickr', ['ui.bootstrap'])
    .service('tagpickr', ['$uibModal', '$q', 'tagService', tagpickrService])
    .controller('TagPickrController',TagPickrController)
    .service("tagService", function()
	{
		var tagList = jsPageModel.tags
		
		/* load tag cache */
		var tagCache = {}

		_(tagList).each(function(tag){
			tagCache[tag.key] = tag
		});

		var tagsGrouped = nest(tagList, ['category', 'group'])

		function getCategory(cat){
			return tagsGrouped[cat];
		}

		function getAllGroups(){
			return tagsGrouped;
		}

		function lookup(key)
		{
			return tagCache[key]
		}

		return {
			lookup:lookup
			,getCategory:getCategory
			,getAllGrouped:getAllGroups
		}

	})
	.component("tag", {
		bindings: {
			key: '<'
			,tag: '<'
			,tagClick: '&'
			,action: '<'
		}
		, templateUrl: '/gen/partials/common/tag.tpl.html'
		, controller: ['tagService', function(tagService){
			
			this.$onInit = function(){
				if(!this.tag){
					this.tag = tagService.lookup(this.key);
				}

                if(this.action == 'add' && this.tag.selected === true){
                    this.hide = true;
                }
			}


		}]
	})

/*
        vm.editTagSet = function(entity, property, title, category){
			var model = _.get(entity,property) || [];
			openTagPicker(title, model, category)
			.then(function(newSet){
				_.set(entity,property,newSet);
			})
		}

		var openTagPicker = function(title, model, category){
			var catTags = vm.tags[category]
			return tagpickr.pick(title, model, catTags)
        }
*/


  /** @ngInject */
  function tagpickrService($uibModal, $q, tagService)
  {
       var tags = tagService.getAllGrouped();

       function pick(title, currentSelectionKeys, category)
       {
           var availableTagsCopy = angular.copy(tags[category]); // copy the available tags as we'll add/remove in this dialog

           var currentSelectionTags = []; // holder for tag objects
           
           /* for already selected (keys), lookup the tag and move it from available to selected arrays */
           _(availableTagsCopy).each(function(list){
                _(list).each(function(tag){
                    if( _(currentSelectionKeys).includes(tag.key)){
                        tag.selected = true;
                        currentSelectionTags.push(tag);
                    }
                })
           })

           var opts = {
               title: title,
               selected: currentSelectionTags,
               available: availableTagsCopy
           }

           return $uibModal.open({
                templateUrl: '/gen/partials/common/tagpickr.html', //'tagpickr.modal.html',
                size: 'lg',
                controller: 'TagPickrController',
                controllerAs: 'vm',
                resolve: {
                    opts: function () {
                        return opts;
                    }
                }
           }).result.then(function(resp){
               if(resp === 'OK'){
                   return $q.resolve( _(currentSelectionTags).map(function(tag){ return tag.key }).value() );
               }
               else {
                   return $q.resolve(currentSelectionKeys);
               }
           }, function(){
               return $q.resolve(currentSelectionKeys);
           })
       }

    return {
      pick: pick
    };
}

/** @ngInject */
function TagPickrController(opts, tagService)
{
    var vm = this;
    vm.opts = opts;

    vm.select = function(tag){
        vm.opts.selected.push(tag);
        tag.selected = true;
    }

    vm.unselect = function(tag){
        _.remove(vm.opts.selected, function(atag){ 
            return atag.key == tag.key 
        })
        delete tag.selected;
    }
}

TagPickrController.$inject = ['opts', 'tagService'];


angular.module('ng-tagpickr')
    .run(['$templateCache', function($templateCache) {
        $templateCache.put('tagpickr.modal.html','<div class="modal-header">\n    <h3 class="modal-title">{{vm.opts.title}}</h3>\n</div>\n<div class="modal-body">\n <ul><li ng-repeat="tag in vm.opts.selected">{{selected}}</li></ul><hr> <ul><li ng-repeat="tag in vm.opts.available">{{selected}}</li></ul> </div>\n<div class="modal-footer">\n    <button class="btn btn-warning" type="button" ng-click="$dismiss()">Cancel</button>\n    <button class="btn btn-success" type="button" ng-click="$close(\'OK\')">Update</button>\n</div>');
    }]);

var nest = function (seq, keys) {
    if (!keys.length)
        return seq;
    var first = keys[0];
    var rest = keys.slice(1);
    return _.mapValues(_.groupBy(seq, first), function (value) { 
        return nest(value, rest)
    });
};


})();