'use strict'

;(function(){
	PNotify.prototype.options.styling = "fontawesome";

	PNotify.err = function(msg){
	    new PNotify({
	        title: 'Error',
	        text: msg,
	        type: 'error',
	        nonblock: {
	            nonblock: true,
	            nonblock_opacity: .2
	        }
	    });
	};
	
	PNotify.ok = function(msg){
	    new PNotify({
	        title: 'Success',
	        text: msg,
	        type: 'success'
	    });
	};

	PNotify.warn = function(msg){
	    new PNotify({
	        title: 'Warning',
	        text: msg,
	        type: 'warning'
	    });
	};
})();

