'use strict'
;(function boot(){
	
	angular.module('inc-reports', ['ui.router'])
	
	.config(['$stateProvider', '$urlRouterProvider', function routeCfg($stateProvider, $urlRouterProvider) {

		$stateProvider.state({
			name:'reports',
			abstract: true,
			templateUrl:'/gen/partials/reports/reports.html',
			controller:'ReportsBaseController',
			controllerAs:'vm'
		})
		.state({
			name:'reports.list',
			url:'/list',
			templateUrl:'/gen/partials/reports/reports-list.html',
			controller:'ReportsListController',
			controllerAs:'vm',
		})
		.state({
			name:'reports.run',
			url:'/run/:reportId',
			templateUrl:'/gen/partials/reports/reports-run.html',
			controller:'ReportsRunController',
			controllerAs:'vm'
		})
		;
		$urlRouterProvider.otherwise('/list');
	}])

	.factory('reportsRepo', ['$http', '$q', function($http, $q){

		return {
			reports: [],
			fetch: function(){
				var repo = this;
				if( ! this.reports.length ){
					return $http.get("/api/v1/reports/" + jsPageModel.team + "/jr/list")
					.then(function(resp){
						repo.reports = resp.data;
						return repo.reports;
					})
				} else {
					return $q.resolve(this.reports);
				}
				
			}
		}

	}])
	
	.controller('ReportsBaseController', [ '$http', 
									 function reportsBaseController( $http ){
		var vm = this;
	}])


	.controller('ReportsListController', [ '$http', 'reportsRepo',
									 function reportsBaseController( $http, repo ){
		var vm = this;
		repo.fetch().then(function(list){
			vm.reports = list;
		})

		vm.team = jsPageModel.team;
	}])

	.controller('ReportsRunController', [ '$http', '$stateParams', '$state', 'reportsRepo', '$timeout',
									 function reportsBaseController( $http, $stateParams, $state, repo, $timeout ){
		var vm = this;

		vm.years = _.range(2018, new Date().getFullYear() + 1); // 1+last
		
		vm.reportId = $stateParams['reportId']

		if(vm.reportId.indexOf('export:') == 0) {
			vm.report = {
				"id":vm.reportId,
				"name": "Data Export", 
				"desc": "Data Export", 
				"reportClass": "uk.org.mrew.incweb.reporting.dyn.data.DataExport",
			}
			vm.reportParams = [
				{ description: "Date Start", name: "DATE_START", setByIncweb: false, valueClassName: "java.util.Date" },
				{description: "Date End", name: "DATE_END", setByIncweb: false, valueClassName: "java.util.Date"}
			]
			vm.showDateHelper = true;
			vm.show_obs = false;
			vm.excel_only = true;
		}
		else {
			vm.show_obs = true;

			repo.fetch().then(function(list){
				vm.report = _.find(list, function(r){ return r.id == vm.reportId });
				$http.get(vm.formAction)
				.then(function(resp){
					vm.reportParams = resp.data;
	
					// TODO date helper?
					
					if (_.filter(vm.reportParams, function(param){
						return param.name == 'DATE_START' || param.name == 'DATE_END'
					}).length == 2){
						vm.showDateHelper = true;
					}
	
					if ( _.some(vm.reportParams, function(param){
							return param.name == 'YEAR'
						}) ){
						vm.showYearPicker = true;
					}
					vm.paramValues = {}
				})
	
			})
		}

		vm.dateRangeType = 'month';
		vm.paramValues = {}
		vm.formAction = "/api/v1/reports/" + jsPageModel.team + "/jr/" + vm.reportId;	
		
		vm.pickadate_opts = {
			format: 'mmmm dd, yyyy',
			formatSubmit: 'yyyy-mm-dd',
			hiddenName: true,
			selectYears: 40,
			
		}

		vm.onDateRangeTypeChange = function(){
			var d = vm.dateRangeType;
			var from = moment();
			var to = moment(); 

			if(vm.dateRangeType == 'custom'){
				return;
			}
			var parts = vm.dateRangeType.split(":")
			
			// we did support last X days (E.g. 'days:-7' ) - see git log prior to 01/12/19
			from = from.startOf(parts[0])
			if(parts.length > 0){
				from = from.add( +parts[1], parts[0] )
			}
			to = from.clone().endOf(parts[0])
			
			
			vm.paramValues['DATE_START'] = from.format('MMMM DD, YYYY')
			vm.paramValues['DATE_END'] = to.format('MMMM DD, YYYY')

			$("form#jrParams input[name=DATE_START]").val(from.format('YYYY-MM-DD'))
			$("form#jrParams input[name=DATE_END]").val(to.format('YYYY-MM-DD'))
		}

		$timeout(function(){
			vm.onDateRangeTypeChange();
		}, 500);
		

		
	}])

	.filter('export', function() {
		return function(list, show_exporters) {
		  return _.filter(list, function(it){
			  if(show_exporters){
				return it.id.indexOf('export') == 0
			  }
			  else {
				return it.id.indexOf('export') == -1
			  }
			  
		  })
		};
	  })

	  
	




})();
